<template>
  <div class="main-desktop-tabs-area">
    <draggable
      ref="draggableRef"
      v-model="draggableItems"
      item-key="key"
      :animation="200"
      :disabled
      class="main-desktop-tabs-area__container"
    >
      <template #item="{ element: item, index }">
        <div
          class="main-desktop-tabs-area__item"
          :class="
            hiddenItemsCount &&
            index >= draggableItems.length - hiddenItemsCount - 1
              ? item.active
                ? 'order-1'
                : 'order-2'
              : undefined
          "
        >
          <MainDesktopTabsPrimaryItem :item :disabled />
        </div>
      </template>
    </draggable>
    <UIDropdown
      v-if="isMoreButtonVisible"
      :disabled
      popper-class="main-desktop-tabs-area__more-popper"
    >
      <template #default="{ isShown, toggle }">
        <UIButton
          :disabled
          variant="gray"
          fill="light"
          size="xxs"
          :label="`${hiddenItemsCount} more...`"
          class="main-desktop-tabs-area__more"
          :class="{
            'main-desktop-tabs-area__more--active': isShown,
          }"
          @click="toggle"
        />
      </template>
      <template #popper="{ hide }">
        <draggable
          v-model="draggableItems"
          item-key="key"
          :animation="200"
          :disabled
          class="main-desktop-tabs-area__dropdown"
        >
          <template #item="{ element: item }">
            <MainDesktopTabsDropdownItem :item :disabled @click="hide" />
          </template>
        </draggable>
      </template>
    </UIDropdown>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue'
import { useDebounceFn, useResizeObserver } from '@vueuse/core'
import draggable from 'vuedraggable'
import { orderBy } from 'lodash'

import { NavigationItem } from '@types'

import { useUserSettingsStore } from '@/store/user/settings'

import { UIButton } from '@ui/buttons'
import { UIDropdown } from '@ui/dropdowns'

import MainDesktopTabsPrimaryItem from './MainDesktopTabsPrimaryItem.vue'
import MainDesktopTabsDropdownItem from './MainDesktopTabsDropdownItem.vue'

type Props = {
  items: NavigationItem[]
  disabled?: boolean
}

const props = defineProps<Props>()

const draggableRef = useTemplateRef('draggableRef')

const userSettingsStore = useUserSettingsStore()

const hiddenItemsCount = ref(0)

const order = computed(() => userSettingsStore.getSortedTabs)

const draggableItems = computed({
  get() {
    return orderBy(props.items, item => order.value.indexOf(`${item.key}`))
  },
  set(value) {
    userSettingsStore.sortTabs(value.map(item => `${item.key}`))
  },
})

const isMoreButtonVisible = computed(
  () => !props.disabled && !!hiddenItemsCount.value,
)

const handleResize = useDebounceFn(entries => {
  const target = entries[0]?.target
  if (!target) return
  hiddenItemsCount.value = target.querySelectorAll(
    '.main-desktop-tabs-primary-item--hidden',
  ).length
}, 300)

useResizeObserver(draggableRef, handleResize)
</script>

<style>
.main-desktop-tabs-area {
  @apply flex items-center;
  @apply pr-1;
  @apply overflow-hidden;

  &__container {
    @apply h-12;
    @apply flex;
    @apply px-1 gap-4;
    @apply overflow-hidden;
  }

  &__item {
    @apply h-full;
    @apply flex;

    &:has(> .main-desktop-tabs-primary-item--hidden) {
      @apply opacity-0;
      @apply pointer-events-none;
    }
  }

  &__dropdown {
    @apply py-1;
  }

  &__more {
    @apply w-16;
    @apply px-0;
    @apply whitespace-nowrap;

    &--active {
      @apply bg-gray-100 dark:bg-gray-800;
    }
  }

  &__more-popper {
    .v-popper__inner {
      @apply min-w-40;
    }
  }
}
</style>
