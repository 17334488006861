<template>
  <UIButton
    :leading="togglerIcon"
    variant="secondary"
    size="sm"
    fill="light"
    class="timeline-toggler"
    :class="buttonClasses"
    @click="handleClick"
  >
    {{ t('Timeline') }}
    <template v-if="modelValue">
      <div class="timeline-toggler__arrow timeline-toggler__arrow--left" />
      <div class="timeline-toggler__arrow timeline-toggler__arrow--right" />
    </template>
  </UIButton>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useLocale } from '@/plugins/i18n'

import { ClockIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui/buttons'

const modelValue = defineModel<boolean>()

const { t } = useLocale('components.App.Timeline')

const togglerIcon = computed(() => (modelValue.value ? XMarkIcon : ClockIcon))

const buttonClasses = computed(() => ({
  'timeline-toggler--open': modelValue.value,
}))

const handleClick = () => {
  modelValue.value = !modelValue.value
}
</script>

<style>
.timeline-toggler {
  @apply absolute top-1/2 right-3 sm:right-5;
  @apply -translate-y-1/2;
  @apply flex flex-row-reverse items-center;
  @apply gap-0.5 pl-2 pr-1;
  @apply !bg-transparent;
  @apply !border !border-b-0 !border-transparent;
  @apply text-gray-400 hover:text-primary hover:dark:text-indigo-300;
  @apply !ring-0 !ring-offset-0;
  @apply cursor-pointer;

  .app-timeline__container & {
    @apply static top-auto right-auto;
    @apply translate-y-0;
    @apply -mr-2;
  }

  &--open {
    @apply rounded-b-none;
  }

  &--open:is(&--short) {
    @apply h-[2.25rem];
    @apply pb-1.5 mt-[.1875rem];
    @apply !bg-body-dark;
  }

  &--open:not(&--short) {
    @apply !bg-body-gray;
    @apply !border-light-border;

    @apply after:absolute after:-inset-x-[1px] after:top-full;
    @apply after:h-[calc(0.5rem+2px)] after:bg-body-gray;
    @apply after:border-x after:border-light-border;
  }

  .ui-button__icon {
    @apply size-7;
  }

  &--open .ui-button__icon {
    @apply size-5;
    @apply mx-1;
  }

  .ui-button__label {
    @apply text-xs;
  }

  &__arrow {
    @apply absolute top-full;
    @apply size-[calc(0.5rem+2px)];
    @apply bg-body-gray;
    @apply z-10;

    @apply before:content-[''];
    @apply before:absolute before:inset-0;
    @apply before:bg-body;
    @apply before:border before:border-light-border before:border-t-0;

    &--left {
      @apply right-full;
      @apply before:border-l-0;
      @apply before:rounded-ee-md;
    }

    &--right {
      @apply left-full;
      @apply before:border-r-0;
      @apply before:rounded-es-md;
    }
  }

  &--short &__arrow {
    @apply top-auto bottom-0;
    @apply bg-body-dark;
    @apply before:border-0 before:bg-body-gray;

    &--left {
      @apply -translate-x-[1px];
    }

    &--right {
      @apply translate-x-[1px];
    }
  }
}
</style>
