<template>
  <div v-if="isNavbarOpened" class="navbar-node" @click="handleClick">
    <div class="navbar-node__icon">
      <PlusIcon aria-hidden="true" />
    </div>
    <div class="navbar-node__label">{{ t('Add asset') }}</div>
  </div>
  <UIButtonIcon
    v-else
    :label="t('Add asset')"
    :leading="PlusIcon"
    variant="gray"
    fill="outlined"
    size="sm"
    class="navbar-item-menu"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { inject, markRaw } from 'vue'

import { OpenedFlag, ReadonlyMode } from '@types'

import { NAVBAR_OPENED, READONLY_MODE } from '@/const'

import { useLocale } from '@/plugins/i18n'

import { useModalsStore } from '@/store/modals'

import AddAssetDialog from './components/AddAssetDialog.vue'
import { UIButtonIcon } from '@ui/buttons'
import { PlusIcon } from '@heroicons/vue/24/solid'

const { t } = useLocale('layouts.Main')

const modalsStore = useModalsStore()

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)
const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const handleClick = () => {
  if (isReadonly?.value) return
  const modalInstance = modalsStore.init(
    'main-add-asset',
    markRaw(AddAssetDialog),
  )
  modalInstance?.open(modalsStore.getZIndex())
}
</script>
