<template>
  <div ref="timelineRef" class="app-timeline" :class="mainClasses">
    <div class="app-timeline__container">
      <slot />
      <template v-if="!isShortMode">
        <UIValuesGuide
          v-model="sliderDate"
          :values="dates"
          variant="gray"
          size="xs"
          fill="light"
          :disabled
          :loading="isLoading"
          :formatter="dateFormatter"
          :getter-fn="valueGetterFn"
        />
        <TimelineToggler v-model="isOpen" :is-short-mode />
      </template>
    </div>
    <div v-if="isShortMode || isOpen" class="app-timeline__panel">
      <div v-if="isShortMode" class="app-timeline__panel-area">
        <UIValuesGuide
          v-model="sliderDate"
          :values="dates"
          variant="gray"
          size="xs"
          fill="light"
          :disabled
          :loading="isLoading"
          :formatter="dateFormatter"
          :getter-fn="valueGetterFn"
        />
        <TimelineToggler v-model="isOpen" class="timeline-toggler--short" />
      </div>
      <div v-if="isOpen" class="app-timeline__panel-fields">
        <UITimeline
          v-model="sliderDate"
          v-bind="{ dates, disabled, key, timeline }"
          @update:timeline="handleUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue'

import { RepositoryTimeline } from '@types'

import { stringToDateTime, stringToLocalDateString } from '@/helpers/dates'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'
import { useUserSettingsStore } from '@/store/user/settings'

import { UITimeline, UIValuesGuide } from '@ui/core'
import TimelineToggler from './TimelineToggler.vue'
import { useResizeObserver } from '@vueuse/core'

type Props = {
  disabled?: boolean
  hideSlider?: boolean
}

defineProps<Props>()

defineOptions({
  name: 'AppTimeline',
})

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()
const userSettingsStore = useUserSettingsStore()

const timelineRef = useTemplateRef('timelineRef')

const isShortMode = ref(false)

useResizeObserver(timelineRef, entries => {
  isShortMode.value = entries[0]?.target
    ? entries[0]?.target?.clientWidth < 400
    : false
})

const isOpen = computed({
  get() {
    return userSettingsStore.getTimelineOpened
  },
  set(value) {
    userSettingsStore.setTimelineOpened(value)
  },
})

const key = computed(() => repositoriesStore.getCurrentRepository?.id)
const timeline = computed(() => repositoriesStore.getCurrentTimeline)

const dates = computed(() => analyticsStore.getDatesRange)

const isLoading = computed(() => dates.value.length === 0)

const sliderDate = computed({
  get() {
    return analyticsStore.getDate
  },
  set(value) {
    analyticsStore.setDate(value)
  },
})

const mainClasses = computed(() => ({
  'app-timeline--short': isShortMode.value,
  'app-timeline--open': isOpen.value,
}))

const dateFormatter = (date: string) => `${stringToLocalDateString(date)}`

const handleUpdate = async (
  timeline: RepositoryTimeline,
  isEndChanged = false,
) => {
  analyticsStore.cancel()
  analyticsStore.refresh(isEndChanged ? undefined : ['tree'])

  await repositoriesStore.updateTimeline(timeline, isEndChanged)
  analyticsStore.markAsDeprecated(isEndChanged ? [] : ['tree', 'irr_moic'])
}

const valueGetterFn = (item: string, value: string) => {
  const date = stringToDateTime(value)
  return !!(date && stringToDateTime(item)?.hasSame(date, 'day'))
}
</script>

<style>
.app-timeline {
  @apply w-full;
  @apply mt-0.5 md:-mt-2;

  &__container {
    @apply min-h-9;
    @apply flex items-center;
    @apply gap-2 mb-2;

    .ui-preloader {
      @apply mr-auto;
    }
  }

  &__panel {
    @apply -mx-4 sm:-mx-6;
    @apply bg-body-gray;
    @apply border-y border-light-border;
  }

  &__panel-area {
    @apply relative;
    @apply flex justify-center;
    @apply py-1.5 px-4 sm:px-6;

    .app-timeline--short & {
      @apply justify-start;
    }
  }

  &__panel-fields {
    @apply py-2.5 md:py-1.5 px-4 sm:px-6 md:pr-4;

    .app-timeline--short & {
      @apply bg-body-dark;
    }

    .ui-timeline {
      @apply w-full;
      @apply flex justify-center md:justify-end;
    }
  }
}
</style>
