export const STEPS_LIST = [
  {
    name: 'Name & description',
  },
  {
    name: 'Cost',
  },
  {
    name: 'Value',
    description: 'optional',
  },
  {
    name: 'Asset tags',
  },
  {
    name: 'Summary',
  },
]

export const ASSET_CLASS_LIST = ['Real Estate']

export const INVESTMENT_STRATEGY_LIST = [
  'Personal Property',
  'Investment Property',
]
