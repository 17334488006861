<template>
  <slot
    v-bind="{
      isEmptyApp,
      isEmptyAnalytics,
      isEmptyConnectors,
      isWelcomeScreenVisible,
    }"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import useAnalyticsStore from '@/store/analytics'
import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useRepositoriesStore } from '@/store/repositories'

defineOptions({ name: 'MainWrapper' })

const route = useRoute()

const analyticsStore = useAnalyticsStore()
const linkedDataConnectorsStore = useLinkedDataConnectorsStore()
const repositoriesStore = useRepositoriesStore()

const isEmptyApp = computed(() => !repositoriesStore.list.length)

const isAnalyticsLoading = computed(
  () => analyticsStore.isLoading || !analyticsStore.initFlag,
)

const isEmptyAnalytics = computed(() => {
  return (
    !!route.meta.analytics &&
    !isAnalyticsLoading.value &&
    !!analyticsStore.getError
  )
})
const isConnectorsLoading = computed(
  () =>
    linkedDataConnectorsStore.loading || !linkedDataConnectorsStore.initFlag,
)

const isEmptyConnectors = computed(() => {
  return (
    !!route.meta.connectors &&
    !isConnectorsLoading.value &&
    !linkedDataConnectorsStore.getList?.length
  )
})

const isWelcomeScreenVisible = computed(() => {
  return (
    (isEmptyApp.value || isEmptyAnalytics.value || isEmptyConnectors.value) &&
    !route.meta.permanent
  )
})
</script>
