<template>
  <div class="main-desktop-navbar">
    <div class="main-desktop-navbar__menu">
      <template v-for="item in items" :key="item.label">
        <NavbarItemNode v-if="isNavbarOpened" :item :disabled />
        <NavbarItemMenu v-else :item :disabled />
      </template>
      <MainDesktopNavbarAddAsset />
      <MainDesktopNavbarRepository />
    </div>
  </div>
  <MainDesktopPanelAssetTree />
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { NAVBAR_OPENED } from './utils/const'

import { useNavigationStore } from '@/store/navigation'

import MainDesktopNavbarAddAsset from './MainDesktopNavbarAddAsset.vue'
import MainDesktopNavbarRepository from './MainDesktopNavbarRepository.vue'
import MainDesktopPanelAssetTree from './MainDesktopPanelAssetTree.vue'
import NavbarItemMenu from './components/NavbarItemMenu.vue'
import NavbarItemNode from './components/NavbarItemNode.vue'

type Props = {
  disabled?: boolean
}

defineProps<Props>()

const navigationStore = useNavigationStore()

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)

const items = computed(() => navigationStore.getParentNavigation)
</script>

<style>
.main-desktop-navbar {
  @apply w-[4.2rem];
  @apply flex flex-col items-center;
  @apply shrink-0;
  @apply py-2 gap-2;
  @apply bg-body-gray;
  @apply border-r border-common-border;
  @apply z-40;
  @apply transition-all;

  .main-desktop--opened-navbar & {
    @apply w-56;
    @apply items-start;
  }

  &__menu {
    @apply w-full;
    @apply flex flex-col;
    @apply flex-auto;
    @apply gap-2;

    .main-desktop--opened-navbar & {
      @apply w-full;
      @apply px-2 gap-[4px];
    }
  }
}
</style>
