<template>
  <slot :items="orderedItems" :menu-data :current :update-items />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { orderBy } from 'lodash'

import { NavigationItem } from '@types'

import { useNavigationStore } from '@/store/navigation'
import { useUserSettingsStore } from '@/store/user/settings'

type Props = {
  item: NavigationItem
}

const props = defineProps<Props>()

const route = useRoute()

const navigationStore = useNavigationStore()
const userSettingsStore = useUserSettingsStore()

const order = computed(() => userSettingsStore.getSortedTabs)

const items = computed(
  () =>
    (props.item.label &&
      navigationStore.getChildNavigation(props.item.label)) ||
    [],
)

const orderedItems = computed(() =>
  orderBy(items.value, item => order.value.indexOf(`${item.key}`)),
)

const menuData = computed(() =>
  orderedItems.value.map(item => ({
    value: item.key,
    label: `${item.label}`,
    icon: item.icon,
  })),
)

const current = computed(() => {
  const current = items.value?.find(
    item =>
      route.name &&
      (item.route === route.name ||
        item.additional?.includes(route.name?.toString())) &&
      (!item.routeParams ||
        JSON.stringify(item.routeParams) === JSON.stringify(route.params)),
  )
  return (
    current && `${current.route}${JSON.stringify(current.routeParams || {})}`
  )
})

const updateItems = (items: NavigationItem[]) => {
  userSettingsStore.sortTabs(items.map(item => `${item.key}`))
}
</script>

<style>
.navbar-item {
  &--active {
    @apply relative;
    @apply ml-0.5 -mr-[1px] !pr-[calc(0.25rem+1px)];
    @apply bg-body-dark;
    @apply !border-common-border;

    .ui-button {
      @apply bg-transparent hover:bg-body-gray;
      @apply border-common-border;
    }
  }

  &__arrow {
    @apply absolute right-0;
    @apply size-2;
    @apply bg-body-dark;

    @apply before:content-[''];
    @apply before:absolute before:inset-0;
    @apply before:bg-body-gray;
    @apply before:border before:border-common-border before:border-l-0;

    &--top {
      @apply bottom-full;
      @apply before:border-t-0;
      @apply before:rounded-ee-[5px];
    }

    &--bottom {
      @apply top-full;
      @apply before:border-b-0;
      @apply before:rounded-se-[5px];
    }
  }

  &__status {
    @apply inline;
    @apply align-top;
    @apply size-2.5;
    @apply text-indigo-400;

    &--float {
      @apply absolute;
      @apply top-[0.25rem] left-1/2;
      @apply translate-x-[.35rem];
    }
  }

  &--active &__status {
    @apply -ml-[1px];
  }
}
</style>
