<template>
  <template v-if="isComponentVisible">
    <slot v-bind="{ data }" />
  </template>
</template>

<script setup lang="ts">
import { computed, markRaw } from 'vue'
import { useDark, useToggle } from '@vueuse/core'

import {
  ArrowRightEndOnRectangleIcon,
  Cog6ToothIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/vue/24/outline'

import { useModalsStore } from '@/store/modals'
import { useUserStore } from '@/store/user'

import ModalsSettings from '@/modules/modals/Settings/Settings.vue'
import { DropdownActionsData } from '@types'

defineOptions({ name: 'MainUser' })

const modalsStore = useModalsStore()
const userStore = useUserStore()

const isDark = useDark()
const toggleDark = useToggle(isDark)

const darkThemeIcon = computed(() => (isDark.value ? SunIcon : MoonIcon))

const isComponentVisible = computed(() => userStore.getEmail)

const data = computed<DropdownActionsData[]>(() => [
  {
    label: isDark.value ? 'Light Theme' : 'Dark Theme',
    icon: darkThemeIcon.value,
    action: _ => {
      toggleDark()
    },
  },
  {
    label: 'Settings',
    icon: Cog6ToothIcon,
    action: (hide: () => void) => {
      hide()
      const modalInstance = modalsStore.init(
        'settings',
        markRaw(ModalsSettings),
      )
      modalInstance?.open(modalsStore.getZIndex(), {})
    },
  },
  {
    label: 'Sign out',
    icon: ArrowRightEndOnRectangleIcon,
    action: handleSignOut,
  },
])

const handleSignOut = async () => {
  await userStore.signOut()
}
</script>
