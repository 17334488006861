<template>
  <div class="main-desktop" :class="mainClasses">
    <MainWrapper>
      <template
        #default="{
          isEmptyApp,
          isEmptyAnalytics,
          isEmptyConnectors,
          isWelcomeScreenVisible,
        }"
      >
        <MainDesktopHeader :disabled="isEmptyApp" />
        <div class="main-desktop__container">
          <MainDesktopNavbar v-if="!isEmptyApp" :disabled="isEditMode" />
          <main class="main-desktop__content">
            <AppAlerts />
            <AppWelcomeScreen
              v-if="isWelcomeScreenVisible"
              v-bind="{ isEmptyApp, isEmptyAnalytics, isEmptyConnectors }"
            />
            <template v-else>
              <MainDesktopTabs v-if="isTabsVisible" :disabled="isEditMode" />
              <AppAnalyticsProgressBar class="main-desktop__progress" />
              <router-view v-slot="{ Component }">
                <component :is="Component" />
              </router-view>
            </template>
          </main>
        </div>
      </template>
    </MainWrapper>
  </div>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { useRoute } from 'vue-router'

import { NAVBAR_OPENED } from './utils/const'

import { useDashboardsSettingsStore } from '@/store/settings/dashboards'

import MainDesktopHeader from './MainDesktopHeader.vue'
import MainDesktopNavbar from './MainDesktopNavbar.vue'
import MainDesktopTabs from './MainDesktopTabs.vue'
import MainWrapper from './components/Wrapper.vue'

import { AppAlerts, AppAnalyticsProgressBar, AppWelcomeScreen } from '@app'

const route = useRoute()

const dashboardSettingsStore = useDashboardsSettingsStore()

const isNavbarOpened = useLocalStorage<boolean>('NAVBAR_OPENED', false)

provide(NAVBAR_OPENED, isNavbarOpened)

const isEditMode = computed(() => dashboardSettingsStore.getEditMode)

const isTabsVisible = computed(() => !route.meta.hideTabs)

const mainClasses = computed(() => ({
  'main-desktop--opened-navbar': isNavbarOpened.value,
}))
</script>

<style scoped>
.main-desktop {
  @apply h-screen flex flex-col;
  @apply md:overflow-hidden;

  &__container {
    @apply flex flex-auto flex-row;
    @apply md:overflow-hidden;
  }

  &__content {
    @apply w-full md:w-auto;
    @apply relative;
    @apply flex flex-col flex-auto;
    @apply px-default pb-0;
    @apply md:overflow-auto;
    @apply transition-all;
  }

  &__toggler {
    @apply -ml-6 mr-1.5;
    @apply relative;
    @apply bg-body-dark;
    @apply border border-l-0 border-common-border;
    @apply rounded-r;
    @apply z-10;
  }

  &__progress {
    @apply -mt-5 first:mt-0 -mx-default mb-4;
  }
}
</style>
