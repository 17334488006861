<template>
  <User>
    <template #default="{ data }">
      <div class="main-mobile-header-button" @click="handleOpen">
        <UserCircleIcon aria-hidden="true" class="main-mobile-header-icon" />
      </div>
      <Teleport to="body">
        <UIModal
          v-bind="{ isOpen }"
          :type="ModalType.SLIDEPANEL"
          direction="to-left"
          size="md"
          header-class="main-mobile-header-user__header"
          wrapper-class="main-mobile-header-user__wrapper"
          hide-minimise-button
          with-back
          @hide="handleClose"
        >
          <template #title>
            <div class="main-mobile-header-user__user">
              <UserCircleIcon
                aria-hidden="true"
                class="main-mobile-header-icon"
              />
              <div class="main-mobile-header-user__email">
                {{ userEmail }}
              </div>
            </div>
          </template>
          <AppUserInfo :hide="handleClose" />
          <MainMobileHeaderUserLink
            v-for="(item, index) in data"
            :key="index"
            v-bind="{ item }"
            @close="handleClose"
          />
          <div class="main-mobile-header-user__version">{{ appVersion }}</div>
        </UIModal>
      </Teleport>
    </template>
  </User>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ModalType } from '@types'

import { useUserStore } from '@/store/user'

import User from './components/User.vue'
import { UserCircleIcon } from '@heroicons/vue/24/outline'
import { AppUserInfo } from '@app'
import { UIModal } from '@ui/modals'
import MainMobileHeaderUserLink from './MainMobileHeaderUserLink.vue'

const isOpen = ref(false)

const appVersion = `${__APP_VERSION__ || ''}`

const userStore = useUserStore()

const userEmail = computed(() => userStore.getEmail)

const handleOpen = () => {
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
}
</script>

<style>
.main-mobile-header-user {
  &__header {
    @apply !items-center;
    @apply !pb-1 !pt-safe-offset-1.5 !pr-3.5 !pl-3;
    @apply bg-primary;

    .ui-button--gray-light {
      @apply !text-white;
      @apply hover:!bg-primary-dark focus:!bg-primary-dark;
      @apply !ring-0 !ring-offset-0;
    }

    .slide-panel-body__title {
      @apply overflow-hidden;
    }

    .main-mobile-header-icon {
      @apply shrink-0;
    }
  }

  &__wrapper {
    @apply !px-0;
  }

  &__user {
    @apply flex items-center;
    @apply gap-2;
  }

  &__email {
    @apply font-normal;
    @apply text-sm;
    @apply truncate;
    @apply text-white;
  }

  &__version {
    @apply mt-auto mb-2;
    @apply text-xs;
    @apply text-light-text;
    @apply text-center;
  }
}
</style>
