<template>
  <div class="wizzard__form">
    <AssetPricesForm
      ref="assetPricesFormRef"
      v-bind="{ defaultDate, placeholder }"
      size="default"
      @submit="handleSubmit"
    />
    <UIList
      v-if="items?.length"
      v-bind="{ columns, items }"
      class="wizzard-values"
      @remove="handleRemove"
    />
    <UILayoutNoData v-else inline hide-question-icon class="flex-auto" />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, markRaw, useTemplateRef } from 'vue'
import { AssetPriceForm } from '@types'

import { numberFormat } from '@/helpers/numbers'
import { stringToLocalDateString } from '@/helpers/dates'

import { useNotifications } from '@/plugins/notification'

import { useModalsStore } from '@/store/modals'

import AssetPricesForm from '@/entities/assets/components/AssetPricesForm.vue'
import { UILayoutNoData, UIList } from '@ui/core'
import ValuesConfirm from './ValuesConfirm.vue'

type Props = {
  placeholder?: string
}

defineProps<Props>()

const items = defineModel<AssetPriceForm[]>({ required: true })

defineExpose({
  checkEmptyValue: async () => {
    const value = assetPricesFormRef.value?.getCloseValue?.()
    return await new Promise(resolve => {
      if (!value) {
        resolve(false)
        return
      }
      const modalInstance = modalsStore.init(
        'wizzard-values-confirm',
        markRaw(ValuesConfirm),
      )
      modalInstance?.open(modalsStore.getZIndex(), {
        onCancel: () => resolve(true),
        onConfirm: () => resolve(false),
      })
    })
  },
})

defineOptions({ inheritAttrs: false })

const modalsStore = useModalsStore()

const { error } = useNotifications()

const defaultDate = inject<ComputedRef<string | undefined>>('defaultDate')

const assetPricesFormRef = useTemplateRef('assetPricesFormRef')

const columns = computed(() => [
  {
    name: 'date',
    caption: 'Date',
    content: '1fr',
    formatter: (value: string) => `${stringToLocalDateString(value)}`,
  },
  {
    name: 'close',
    caption: 'Value',
    content: '1fr',
    headerClass: 'text-right',
    cellClass: 'justify-end',
    formatter: (value: number) => numberFormat(value),
  },
])

const handleSubmit = (data: AssetPriceForm) => {
  if (items.value.find(item => item.date === data.date)) {
    error({
      message: 'Price for this date already exists',
    })
    return
  }
  items.value.push({ ...data })
}

const handleRemove = (index: number) => {
  items.value.splice(index, 1)
}
</script>

<style>
.wizzard-values {
  @apply max-h-[21.5rem];
  @apply overflow-y-auto;
}
</style>
