<template>
  <ViewTabDropdown
    ref="dropdownRef"
    v-bind="{ item, disabled }"
    @edit="isEditMode = true"
    @close="handleClose"
  >
    <div v-if="isEditMode" class="more-section__item">
      <MoreSectionLinkItem v-bind="{ item, disabled }">
        <InputString v-model="labelValue" @save="handleSave" />
      </MoreSectionLinkItem>
    </div>
    <div
      v-else-if="item.action"
      class="more-section__item"
      @click="!disabled && item.action()"
    >
      <MoreSectionLinkItem v-bind="{ item, disabled }" />
    </div>
    <router-link
      v-else
      v-slot="{ isExactActive }"
      ref="linkItemRef"
      :to="{ name: props.item.route, params: props.item.routeParams }"
      active-class=""
      exact-active-class=""
      class="more-section__item group"
      :class="linkClasses"
      @click="handleClick"
      @contextmenu="handleContextMenu"
    >
      <MoreSectionLinkItem
        v-bind="{ item, disabled }"
        :active="isExactActive"
      />
    </router-link>
  </ViewTabDropdown>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue'
import { MaybeElement, onLongPress } from '@vueuse/core'
import { Capacitor } from '@capacitor/core'

import { NavigationItem } from '@types'

import InputString from './InputString.vue'
import MoreSectionLinkItem from './MoreSectionLinkItem.vue'
import ViewTabDropdown from './ViewTabDropdown.vue'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

type Emits = {
  click: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const dropdownRef = useTemplateRef('dropdownRef')
const linkItemRef = useTemplateRef<MaybeElement>('linkItemRef')

const isEditMode = ref(false)
const labelValue = ref(props.item.label || '')

const linkClasses = computed(() => ({
  'more-section__item--disabled': props.disabled,
}))

onLongPress(
  linkItemRef,
  () => {
    if (!Capacitor.isNativePlatform()) return
    dropdownRef.value?.show()
  },
  {
    modifiers: { prevent: true },
  },
)

const handleSave = () => {
  props.item.update?.(labelValue.value)
  isEditMode.value = false
}

const handleClick = () => {
  if (props.disabled) return
  emit('click')
}

const handleClose = () => {
  emit('click')
}

const handleContextMenu = (event: MouseEvent) => {
  event.preventDefault()
  if (props.disabled) return
  dropdownRef.value?.show()
}
</script>

<style>
.more-section__item {
  @apply select-none;

  &--disabled {
    @apply pointer-events-none;
  }
}
</style>
