export const STEPS_LIST = [
  {
    name: 'Investing or Holding Entity',
  },
  {
    name: 'Investment',
  },
  {
    name: 'Contributions',
  },
  {
    name: 'Distributions',
  },
  {
    name: 'Valuation',
    description: 'optional',
  },
  {
    name: 'Asset tags',
  },
  {
    name: 'Summary',
  },
]

export const ASSET_TYPE_LIST = ['private fund']

export const ASSET_CLASS_LIST = ['Alternative Investments']

export const INVESTMENT_STRATEGY_LIST = [
  'Private Equity',
  'Private Credit',
  'Growth & Venture',
  'Life Insurance',
]
export const INVESTMENT_STRATEGY_LIST_NO_DRAWDOWN = ['Hedge Funds']

export const CONTRIBUTION_TYPES = ['capital call', 'asset fees']
export const CONTRIBUTION_TYPES_NO_DRAWDOWN = [
  'capital contribution',
  'asset fees',
]

export const DISTRIBUTION_TYPES = [
  'capital return',
  'recallable capital return',
  'cash dividend',
  'cash interest',
  'realized gain or loss',
  'current income',
]
