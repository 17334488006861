<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog v-bind="{ zIndex }" size="md" :title @hide="handleClose">
      <div class="init-app-modal">
        <InitAppModalIntro
          v-if="currentStep === 0"
          v-model="currentStep"
          @prev="handleClose"
          @next="currentStep++"
        />
        <InitAppModalForm
          v-if="currentStep === 1"
          v-model="currentStep"
          @prev="currentStep--"
          @next="handleClose"
        />
      </div>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { ModalClass, ModalState } from '@types'

import { UIDialog } from '@ui/modals'

import InitAppModalForm from './InitAppModalForm.vue'
import InitAppModalIntro from './InitAppModalIntro.vue'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

const currentStep = ref(0)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)
const zIndex = computed(() => props.modal.zIndex)

const title = computed(() => {
  switch (currentStep.value) {
    case 0:
      return 'Install an authentication app on your phone'
    case 1:
      return 'Scan the QR code using your authentication app'
  }
})

const handleClose = () => {
  props.modal.close()
}
</script>

<style>
.init-app-modal {
  @apply flex flex-col gap-6;
}
</style>
