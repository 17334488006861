<template>
  <div class="create-actions">
    <slot v-bind="{ items, disabled }">
      <UIButtonIcon
        v-for="item in items"
        :key="item.label"
        v-bind="{ disabled }"
        :leading="item.icon"
        :label="item.label"
        variant="gray"
        fill="outlined"
        size="2xl"
        @click="item.action"
      />
      <div v-if="syncing" class="create-actions__loading">
        <UIPreloader message="Your accounts are now being refreshed" />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed, markRaw } from 'vue'

import { useModalsStore } from '@/store/modals'
import { useLinkedDataConnector } from '@/hooks/linkedDataConnector'

import { UIPreloader } from '@ui/core'
import { UIButtonIcon } from '@ui/buttons'

import {
  BanknotesIcon,
  HomeModernIcon,
  PaintBrushIcon,
} from '@heroicons/vue/24/outline'
import ArchIcon from '@/assets/images/icons/arch.svg'
import PrivateFundIcon from '@/assets/images/icons/private-fund.svg'
import PrivateSecurityIcon from '@/assets/images/icons/private-security.svg'

import AddAccount from '@/views/LinkedData/Connectors/components/AddAccount.vue'
import ArchAccount from '@/modules/wizards/ArchAccount/ArchAccount.vue'
import PrivateFund from '@/modules/wizards/PrivateFund/PrivateFund.vue'
import FineArtAndCollectables from '@/modules/wizards/FineArtAndCollectables/FineArtAndCollectables.vue'
import RealEstate from '@/modules/wizards/RealEstate/RealEstate.vue'
import PrivateSecurityOrOther from '@/modules/wizards/PrivateSecurityOrOther/PrivateSecurityOrOther.vue'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const { loading, syncing } = useLinkedDataConnector()

const modalsStore = useModalsStore()

const disabled = computed(() => syncing.value || loading.value)

const items = computed(() => [
  {
    icon: BanknotesIcon,
    label: 'Connect Bank, Investment & Crypto Accounts',
    action: handleClickAddAccount,
  },
  {
    icon: HomeModernIcon,
    label: 'Add Real Estate',
    action: handleClickCreateRealEstate,
  },
  {
    icon: PrivateFundIcon,
    label: 'Add Private Fund',
    action: handleClickCreatePrivateFund,
  },
  {
    icon: PrivateSecurityIcon,
    label: 'Add Private Security or Other',
    action: handleClickCreatePrivateSecurityOrOther,
  },
  {
    icon: PaintBrushIcon,
    label: 'Add Fine Art and Collectables',
    action: handleClickCreateFineArtAndCollectables,
  },
  {
    icon: ArchIcon,
    label: 'Add Arch Account',
    action: handleClickAddArchAccount,
  },
])

const handleClickAddAccount = () => {
  emit('click')
  const modalInstance = modalsStore.init('add-account', markRaw(AddAccount))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickAddArchAccount = () => {
  emit('click')
  const modalInstance = modalsStore.init('arch-account', markRaw(ArchAccount))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreateRealEstate = () => {
  emit('click')
  const modalInstance = modalsStore.init('real-estate', markRaw(RealEstate))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreatePrivateFund = () => {
  emit('click')
  const modalInstance = modalsStore.init('private-fund', markRaw(PrivateFund))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreatePrivateSecurityOrOther = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'private-security-or-other',
    markRaw(PrivateSecurityOrOther),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreateFineArtAndCollectables = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'fine-art-and-collectables',
    markRaw(FineArtAndCollectables),
  )
  modalInstance?.open(modalsStore.getZIndex())
}
</script>

<style scoped>
.create-actions {
  @apply grid grid-cols-2;
  @apply gap-6 sm:p-4;

  &__loading {
    @apply absolute inset-0;
    @apply flex items-center justify-center;
    @apply bg-white dark:bg-gray-800 bg-opacity-80;
  }
}
</style>
