<template>
  <div class="analytics-progress-bar">
    <UIProgressBar :progress />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAnalyticsStore from '@/store/analytics'

import { UIProgressBar } from '@ui/core'

const analyticsStore = useAnalyticsStore()
const progress = computed(() => analyticsStore.progress)
</script>

<style>
.analytics-progress-bar {
  @apply z-10;
}
</style>
