import { AssetClass } from '@/entities/assets'
import { DocumentClass } from '@/entities/documents'
import { TagClass } from '@/entities/tags'
import { SearchSummary } from './types'
import { Transaction, TransactionSettings } from '@types'

import { DOCUMENT_FIELD } from '@/entities/documents/utils/const'
import { ASSET_FIELD } from '@/entities/assets/utils/const'

import { getDocumentTagName } from '@/helpers/documents'
import { stringToLocalDateString } from '@/helpers/dates'
import { getTransactionString } from '@/views/Data/Transactions/components/utils/helpers'

export const filterFunction = (item: SearchSummary, string: string) =>
  item.result.toLowerCase().includes(string.toLowerCase())

export const prepareTagsSearchList =
  (name: string, routeName: string) =>
  ([, instance]: [string, TagClass]) => ({
    id: instance.id,
    result: instance.displayPair,
    type: `${name} / ${instance.displayPair}`,
    route: { name: routeName, params: { id: instance.id } },
  })

export const prepareAssetsSearchList =
  (name: string, routeName: string) =>
  ([, instance]: [string, AssetClass]) => {
    const searchData = [
      ...new Set([
        instance.field(ASSET_FIELD.NAME).value,
        instance.field(ASSET_FIELD.TICKER).value,
        instance.field(ASSET_FIELD.TYPE).value,
        instance.field(ASSET_FIELD.CURRENCY).value,
      ]),
    ]
    return {
      id: instance.id,
      result: searchData.join(', '),
      type: `${name} / ${instance.field(ASSET_FIELD.NAME).value}`,
      route: { name: routeName, params: { id: instance.id } },
    }
  }

export const prepareAccountsSearchList =
  (name: string, routeName: string) =>
  ([, instance]: [string, AssetClass]) => {
    const searchData = [
      ...new Set([
        instance.field(ASSET_FIELD.NAME).value,
        instance.displayCategory,
        instance.displaySubcategory,
      ]),
    ]
    return {
      id: instance.id,
      result: searchData.join(', '),
      type: `${name} / ${instance.field(ASSET_FIELD.NAME).value}`,
      route: {
        name: routeName,
        params: { id: instance.id },
      },
    }
  }

export const prepareDocumentsSearchList =
  (name: string, routeName: string, assetsBunch: Map<string, AssetClass>) =>
  ([, instance]: [string, DocumentClass]) => {
    const assetId = instance.field<string>(DOCUMENT_FIELD.ASSET_ID).value
    const assetInstance = assetsBunch.get(assetId)
    const searchData = [
      ...new Set([
        instance.field(DOCUMENT_FIELD.NAME).value,
        instance.field(DOCUMENT_FIELD.PATH).value,
        assetInstance?.field(ASSET_FIELD.NAME).value,
        instance
          .field<string[]>(DOCUMENT_FIELD.TAGS)
          .value.map(tag => getDocumentTagName(tag)),
      ]),
    ]
    return {
      id: instance.id,
      result: searchData.join(', '),
      type: `${name} / ${instance.field(DOCUMENT_FIELD.NAME).value}`,
      route: { name: routeName, params: { id: instance.id } },
    }
  }

export const prepareTransactionsSearchList =
  (
    name: string,
    routeName: string,
    settings: TransactionSettings[],
    getAssetName: (id: string) => string | undefined,
  ) =>
  (item: Transaction) => {
    const formattedDate = stringToLocalDateString(item.date)
    const str = getTransactionString(item, settings, getAssetName)
    const searchData = [
      ...new Set([
        ...Object.values(str || {})
          .flat()
          .map(item => item.value),
        ...(item.description ? [item.description] : []),
        item.type,
        formattedDate,
      ]),
    ]
    return {
      id: item.id,
      result: searchData.join(', '),
      type: `${name} / ${item.type}`,
      additional: formattedDate,
      route: {
        name: routeName,
        params: { id: item.id },
      },
    }
  }
