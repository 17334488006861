<template>
  <UICard v-if="isBlockVisible" title="Hot keys">
    <div class="settings-common-hot-keys">
      <p>
        <code>{{ commandKey }} + K</code> - open global search
      </p>
      <p><code>Q</code> - open/close navbar</p>
      <p><code>/</code> - open/close asset tree</p>
    </div>
  </UICard>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Capacitor } from '@capacitor/core'

import { UICard } from '@ui/core'

// @ts-ignore
const platform = navigator?.userAgentData?.platform

const isBlockVisible = computed(() => !Capacitor.isNativePlatform())
const isMac = computed(() => platform === 'macOS')

const commandKey = computed(() => (isMac.value ? '⌘' : 'Ctrl'))
</script>

<style>
.settings-common-hot-keys {
  @apply flex flex-col;
  @apply gap-2;

  code {
    @apply p-1;
    @apply bg-body-gray;
    @apply border border-light-border;
    @apply rounded;
    @apply text-xs;
    @apply text-primary;
  }
}
</style>
