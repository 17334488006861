<template>
  <UIInputDynamicList
    v-model="tagValue"
    :data
    @click:new="handleCreate"
    @ready="onReady"
  />
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue'

import { DropdownListData } from '@types'

import { UIInputDynamicList } from '@ui/fields'

type Props = {
  name: string
  list: DropdownListData[]
  focusOnLoad?: boolean
}

const props = defineProps<Props>()

const tagValue = defineModel<string>({ default: undefined })

const data = ref<DropdownListData[]>([])

const addValueToList = (value: string) => {
  if (data.value.find(item => item.value === value)) return
  data.value.push({ value, label: value })
}

const handleCreate = async (value: string, callback: () => void) => {
  if (value) {
    addValueToList(value)
    tagValue.value = value
  }
  callback()
}

const onReady = (e: any) => {
  if (!props.focusOnLoad) return
  e?.focus()
}

onBeforeMount(() => {
  data.value = props.list
  if (tagValue.value) {
    addValueToList(tagValue.value)
  }
})
</script>
