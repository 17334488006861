<template>
  <NavbarItem :item>
    <template #default="{ items, updateItems }">
      <component
        :is="disabled ? 'div' : 'RouterLink'"
        :to="
          disabled ? undefined : { name: item.route, params: item.routeParams }
        "
        :data-refid="dataRefId"
        class="navbar-node"
        :class="mainClasses"
      >
        <div class="navbar-node__icon">
          <component :is="item.icon" aria-hidden="true" />
        </div>
        <div class="navbar-node__label">{{ item.label }}</div>
        <AddViewButton v-if="isPlusViewButtonVisible">
          <template #default="{ icon, onClick }">
            <UIButtonIcon
              :disabled
              variant="secondary"
              size="xxs"
              :trailing="icon"
              class="navbar-node__button"
              @click.prevent="onClick"
            />
          </template>
        </AddViewButton>
        <UIButtonIcon
          v-if="isAddAccountButtonVisible"
          variant="secondary"
          size="xxs"
          :trailing="PlusIcon"
          :disabled
          class="navbar-node__button"
          @click.prevent="handleClickAddAccount"
        />
        <UIButtonIcon
          :trailing="togglerIcon"
          variant="gray"
          size="xxs"
          fill="light"
          @click.prevent="handleToggle"
        />
      </component>
      <draggable
        v-if="isOpened"
        :model-value="items"
        item-key="key"
        handle=".navbar-node-child__icon"
        :animation="200"
        :disabled
        @update:model-value="updateItems"
      >
        <template #item="{ element: child }">
          <NavbarItemNodeChild :item="child" :disabled />
        </template>
      </draggable>
    </template>
  </NavbarItem>
</template>

<script setup lang="ts">
import { computed, markRaw, ref } from 'vue'
import { upperFirst } from 'lodash'
import draggable from 'vuedraggable'
import { useRoute } from 'vue-router'

import { NavigationItem } from '@types'

import { CONNECTOR_NAME, DASHBOARD_NAME } from '@/const'

import { useModalsStore } from '@/store/modals'

import AddAccount from '@/views/LinkedData/Connectors/components/AddAccount.vue'
import AddViewButton from '@/views/Dashboards/components/AddViewButton.vue'
import NavbarItem from './NavbarItem.vue'
import NavbarItemNodeChild from './NavbarItemNodeChild.vue'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/vue/24/outline'
import { UIButtonIcon } from '@ui/buttons'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const route = useRoute()

const modalsStore = useModalsStore()

const isOpened = ref(false)

const isPlusViewButtonVisible = computed(
  () => props.item.label === DASHBOARD_NAME,
)

const isAddAccountButtonVisible = computed(
  () => props.item.label === CONNECTOR_NAME,
)

const mainClasses = computed(() => {
  return {
    'navbar-node--closed': !isOpened.value,
    'navbar-node--disabled': props.disabled,
    'router-link-exact-active':
      props.disabled &&
      route.matched.find(item => item.name === props.item.route),
  }
})

const dataRefId = computed(
  () =>
    `panelMenuNode${props.item.label
      ?.split(' ')
      .map(item => upperFirst(item))
      .join('')}`,
)

const togglerIcon = computed(() =>
  isOpened.value ? ChevronDownIcon : ChevronRightIcon,
)

const handleToggle = () => {
  isOpened.value = !isOpened.value
}

const handleClickAddAccount = () => {
  const modalInstance = modalsStore.init('add-account', markRaw(AddAccount))
  modalInstance?.open(modalsStore.getZIndex())
}
</script>

<style>
.navbar-node {
  @apply relative;
  @apply flex items-center;
  @apply gap-2 p-1.5;
  @apply hover:bg-body-dark;
  @apply border border-transparent;
  @apply text-gray-700 dark:text-gray-400;
  @apply hover:text-common-text;
  @apply rounded;
  @apply cursor-pointer;

  &--closed.router-link-exact-active {
    @apply !bg-indigo-50 dark:!bg-gray-900;
    @apply border-indigo-100 dark:border-gray-800;
    @apply text-primary dark:text-indigo-400;
  }

  &--disabled {
    @apply opacity-50;
    @apply cursor-default;
  }

  &__icon {
    @apply size-6;
    @apply flex items-center justify-center;
    @apply bg-body-dark;
    @apply border border-common-border;
    @apply rounded;

    .router-link-exact-active & {
      @apply !border-indigo-100 dark:!border-gray-800;
    }

    svg {
      @apply size-4;
    }
  }

  &--active {
    @apply -mr-[calc(0.5rem+1px)] pr-4;
    @apply bg-body-dark;
    @apply border-common-border border-r-0;
    @apply rounded-r-none;
  }

  &--active &__icon {
    @apply border-transparent;
  }

  &__label {
    @apply flex-auto;
    @apply text-sm;
  }

  &__button {
    @apply hidden;
    @apply -my-0.5;
  }

  &:hover &__button {
    @apply block;
  }
}
</style>
