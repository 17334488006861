<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      v-bind="{ zIndex }"
      title="Add Arch Account"
      size="sm"
      @hide="handleClose"
    >
      <div class="arch-account">
        <p>
          To connect your Arch Labs data with Allposit, please contact
          <a href="mailto:support@allposit.com">support@allposit.com</a>. An
          Arch Labs account is required.
        </p>
        <p v-if="isIntercomInited">
          You can also chat with our support team directly.
        </p>
      </div>
      <template v-if="isIntercomInited" #buttons>
        <UIButton label="Chat with Support" @click="handleClickIntercom" />
      </template>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useIntercom } from '@/plugins/intercom'

import { ModalClass, ModalState } from '@types'

import { TransitionRoot } from '@headlessui/vue'
import { UIDialog } from '@ui/modals'
import { UIButton } from '@ui/buttons'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

const intercom = useIntercom()
const isIntercomInited = computed(() => intercom.inited.value)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const handleClose = () => {
  props.modal.close()
}

const handleClickIntercom = () => {
  intercom.showNewMessage(
    'Hi, I’d like to connect my Arch Labs data with Allposit. Could you help me set this up?',
  )
}
</script>

<style>
.arch-account {
  p {
    @apply mb-2 last:mb-0;
  }
  a {
    @apply text-indigo-500 dark:text-indigo-400;
    @apply underline hover:no-underline;
  }
}
</style>
