<template>
  <UIModal
    v-bind="{ isOpen, zIndex }"
    size="sm"
    title="Group accounts"
    :type="ModalType.DIALOG"
    @hide="handleClose"
  >
    <UIPreloader v-if="isLoading" message="Loading..." />
    <form v-else class="assets-grouping" @submit.prevent="onSubmit">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="excludedAssets.length"
        class="assets-grouping__string"
        v-html="excludedAssetsText"
      />
      <!--eslint-enable-->
      <template v-else>
        <div class="text-sm">
          {{ displayAssets }}
        </div>
        <UIInputDynamicList
          v-model="ownerValue"
          :error="errorMessage"
          :disabled="isLoadingAction"
          :data="valuesList"
          label="Owner"
          placeholder="Find or create new account"
          size="sm"
          @click:new="handleCreate"
          @ready="(e: HTMLInputElement) => e.focus()"
        >
          <template #item="{ item, onClick }">
            <div class="ui-dropdown-list-item" @click="onClick">
              <div class="asset-dropdown-item">
                <span>{{ item.label }}</span>
                <span
                  v-if="item.label !== item.meta?.ticker"
                  class="asset-dropdown-item__ticker"
                >
                  {{ item.meta?.ticker }}
                </span>
              </div>
            </div>
          </template>
        </UIInputDynamicList>
      </template>
      <div class="assets-grouping__buttons">
        <UIButtonClose :disabled="isLoadingAction" @click="handleClose" />
        <UIButtonSave
          v-if="!excludedAssets.length"
          :disabled="isLoadingAction || !meta.valid"
          :loading="isLoadingAction"
          label="Group"
        />
      </div>
    </form>
  </UIModal>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { useField, useForm } from 'vee-validate'

import { ModalClass, ModalState, ModalType } from '@types'

import { NOTIFICATION_DELAY } from '@/const'
import { ASSET_FIELD } from '@/entities/assets/utils/const'

import { rules } from '@/helpers/validate'
import { handleCatchedError } from '@/helpers/common'

import { getAssetById } from '@/views/Analytics/utils/helpers'
import { getAssetListValues } from '@/entities/transactions/components/utils/helpers'

import { useNotifications } from '@/plugins/notification'

import { getCurrentDate } from '@/helpers/dates'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useAssetsStore } from '@/store/assets'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import { UIButtonClose, UIButtonSave } from '@ui/buttons'
import { UIPreloader } from '@ui/core'
import { UIModal } from '@ui/modals'
import { UIInputDynamicList } from '@ui/fields'

const GROUPING_TRANSACTION_TYPE = 'add account'
const GROUPING_ASSET_TYPE = 'grouping account'

type Props = {
  ids: string[]
  modal: ModalClass<any>
}

type Emits = {
  complete: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'AssetsGrouping',
})

const { progress, remove, update } = useNotifications()

const analyticsStore = useAnalyticsStore()
const assetsBunchStore = useAssetsBunchStore()
const assetsStore = useAssetsStore()
const transactionsSettingsStore = useTransactionsSettingsStore()
const transactionsStore = useTransactionsStore()

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const defaultDate = computed(() => analyticsStore.getDate || getCurrentDate({}))

const isLoading = computed(() => !transactionsSettingsStore.initFlag)

const isLoadingAction = computed(
  () => transactionsStore.loadingAction || assetsStore.loadingAction,
)
const excludedAssetsText = computed(
  () =>
    `${excludedAssets.value.length > 1 ? 'Assets' : 'Asset'}
    ${excludedAssets.value
      .map(
        asset => `<span class="assets-grouping__highlighted">${asset}</span>`,
      )
      .join(', ')}
    cannot be grouped. Only accounts can be grouped.`,
)

const settings = computed(
  () =>
    transactionsSettingsStore.getList.find(
      item => item.name === GROUPING_TRANSACTION_TYPE,
    )?.entries?.[0],
)

const valuesList = computed(() => {
  const list = Array.from(assetsBunchStore.getList.values())
    .filter(instance => !props.ids.includes(instance.id))
    .map(instance => instance.get())
  return settings.value?.account
    ? getAssetListValues(settings.value?.account, list)
    : []
})

const instanceList = computed(() =>
  props.ids.map(id => getAssetById(id, assetsBunchStore.getList)),
)

const assetsList = computed(() =>
  instanceList.value.filter(asset => {
    return (
      asset !== undefined &&
      settings.value?.asset?.includes?.includes(
        asset?.field<string>(ASSET_FIELD.TYPE).value || '',
      )
    )
  }),
)

const excludedAssets = computed(() =>
  instanceList.value
    .filter(
      asset =>
        !settings.value?.asset?.includes?.includes(
          asset?.field<string>(ASSET_FIELD.TYPE).value || '',
        ),
    )
    .map(asset => asset?.field<string>(ASSET_FIELD.NAME).value),
)

const displayAssets = computed(() =>
  assetsList.value
    .map(asset => asset?.field<string>(ASSET_FIELD.NAME).value)
    .join(', '),
)

const { meta, handleSubmit } = useForm<{ owner: string }>({
  initialValues: {
    owner: '',
  },
  validationSchema: {
    owner: rules.required,
  },
})
const { value: ownerValue, errorMessage, setValue } = useField<string>('owner')

const handleClose = () => {
  props.modal.close()
}

const onSubmit = handleSubmit(async values => {
  const transactions = assetsList.value.map(asset => ({
    date: defaultDate.value,
    type: GROUPING_TRANSACTION_TYPE,
    entries: [
      {
        account_id: values.owner,
        amount: 1,
        asset_id: `${asset?.id}`,
      },
    ],
    description: '',
  }))
  const nid = await progress({
    message: `Grouping assets`,
  })
  try {
    await transactionsStore.bulkStore(transactions)
    await update(
      nid,
      {
        type: 'success',
        message: `Assets are grouped`,
      },
      NOTIFICATION_DELAY,
    )
    transactionsStore.clear()
  } catch (e) {
    await remove(nid)
    handleCatchedError(e as string)
  }
  handleClose()
  emit('complete')
})

const handleCreate = async (value: string, callback?: () => void) => {
  try {
    const instance = assetsBunchStore.createElement()
    instance.set({
      type: GROUPING_ASSET_TYPE,
      name: value,
      ticker: value,
    })
    await instance.store()
    if (instance.id) {
      setValue(instance.id)
      onSubmit()
    }
  } catch (e) {
    handleCatchedError(e as string)
  } finally {
    callback?.()
  }
}

watch(isOpen, value => {
  if (value) return
  setValue('', false)
})

onMounted(() => {
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>

<style>
.assets-grouping {
  @apply flex flex-col;
  @apply gap-y-4;

  &__string {
    @apply text-gray-400;
  }

  &__highlighted {
    @apply text-gray-700 dark:text-gray-200;
  }

  &__buttons {
    @apply flex items-center justify-end;
    @apply gap-x-2;
  }
}
</style>
