<template>
  <UIInputText
    placeholder="Global search"
    size="sm"
    readonly
    class="main-mobile-header-search__input"
    @click="handleOpen"
  >
    <template #trailing>
      <MagnifyingGlassIcon aria-hidden="true" />
    </template>
  </UIInputText>
  <Teleport to="body">
    <UIModal
      v-bind="{ isOpen }"
      :type="ModalType.SLIDEPANEL"
      title="Global Search"
      direction="to-top"
      wrapper-class="main-mobile-header-search"
      size="full"
      hide-minimise-button
      with-back
      @hide="handleClose"
    >
      <AppGlobalSearch
        v-model:is-open="isOpen"
        v-model:search="search"
        v-model:current-tag="currentTag"
        v-bind="{ itemsFound, tags }"
        @result:click="handleClickResult"
      />
    </UIModal>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { ModalType, SearchSummary } from '@types'
import { useRouter } from 'vue-router'

import { ROUTE_NAME } from '@/const'

import { TransactionClass } from '@/entities/transactions'

import { useModalsStore } from '@/store/modals'
import { useSearchStore } from '@/store/search'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import { AppGlobalSearch } from '@app'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { UIModal } from '@ui/modals'
import { UIInputText } from '@ui/fields'

const router = useRouter()

const modalsStore = useModalsStore()
const searchStore = useSearchStore()
const transactionsStore = useTransactionsStore()
const transactionsBunchStore = useTransactionsBunchStore()

const isOpen = ref(false)

const search = computed({
  get() {
    return searchStore.string
  },
  set(value) {
    searchStore.string = value
  },
})

const currentTag = computed({
  get() {
    return searchStore.currentTag
  },
  set(value) {
    searchStore.currentTag = value
  },
})

const tags = computed(() => searchStore.tags)

const itemsFound = computed(() =>
  searchStore.string.trim() !== '' ? searchStore.getSearchSummary : [],
)

const handleOpen = () => {
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
}

const handleClickResult = async (item: SearchSummary) => {
  isOpen.value = false
  if (
    item.route.name === ROUTE_NAME.TRANSACTIONS_ITEM &&
    item.route?.params?.id &&
    !transactionsBunchStore.getList.has(item.route?.params?.id)
  ) {
    const transaction = await transactionsStore.fetchSingle(
      item.route.params.id.toString(),
    )
    const instance = new TransactionClass(transaction)
    const modalInstance = modalsStore.init(instance.id, instance)
    modalInstance?.open(modalsStore.getZIndex())
    return
  }
  router.replace(item.route)
}
</script>

<style>
.main-mobile-header-search {
  @apply pt-0.5;

  &__input {
    @apply cursor-pointer;
  }

  .app-global-search__container {
    @apply -mx-6;
  }

  .app-global-search__item {
    @apply px-6;
  }
}
</style>
