<template>
  <div class="app-notifications__item">
    <div class="app-notifications__header">
      <p class="app-notifications__date">
        {{ displayDate }}
      </p>
      <slot name="header" />
    </div>
    <div class="app-notifications__content">
      <div class="app-notifications__message">
        {{ item.message }}
      </div>
      <div class="app-notifications__status">
        {{ item.status }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { TaskNotify } from './utils/types'

import { EMPTY_VALUE_PLACEHOLDER } from '@/const'

import { stringToLocalDateString } from '@/helpers/dates'

type Props = {
  item: TaskNotify
}

const props = defineProps<Props>()

const displayDate = computed(() =>
  props.item.datetime
    ? stringToLocalDateString(props.item.datetime)
    : EMPTY_VALUE_PLACEHOLDER,
)
</script>

<style>
.app-notifications {
  &__item {
    @apply px-4 py-4 sm:px-6;
  }

  &__header {
    @apply flex items-center justify-between;
  }

  &__date {
    @apply truncate text-xs font-medium text-indigo-600;
  }

  &__content {
    @apply mt-2;
    @apply sm:flex sm:items-center sm:justify-between;
    @apply sm:gap-4;
  }

  &__message {
    @apply sm:max-w-sm;
    @apply sm:flex;
    @apply text-sm text-gray-500;
  }

  &__status {
    @apply mt-2 sm:mt-0;
    @apply flex items-center;
    @apply text-xs text-gray-400 whitespace-nowrap;
  }
}
</style>
