<template>
  <NavbarItem :item>
    <template #default="{ items, menuData, current }">
      <UIDropdownActions
        v-model="shown"
        :data="menuData"
        :current-value="current"
        placement="right-start"
        popper-class="navbar-item-menu__dropdown"
        show-group="menu"
        @click:item="handleClickItem($event, items)"
        @popper:mouseenter="onMouseEnter"
        @popper:mouseleave="onMouseLeave"
      >
        <template #default>
          <component
            :is="disabled ? 'div' : 'RouterLink'"
            :to="
              disabled
                ? undefined
                : { name: item.route, params: item.routeParams }
            "
            :data-refid="dataRefId"
            @mousemove="onMouseEnter"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
          >
            <UIButtonIcon
              :label="item.label"
              :leading="item.icon"
              :variant="item.active ? 'secondary' : 'gray'"
              :fill="item.active ? undefined : 'outlined'"
              :disabled
              size="sm"
              class="navbar-item-menu"
            />
          </component>
        </template>
        <template #before>
          <div class="navbar-item-menu__caption">{{ item.label }}</div>
        </template>
      </UIDropdownActions>
    </template>
  </NavbarItem>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { upperFirst } from 'lodash'
import { useRouter } from 'vue-router'
import { useTimeoutFn } from '@vueuse/core'

import { NavigationItem } from '@types'

import NavbarItem from './NavbarItem.vue'
import { UIButtonIcon } from '@ui/buttons'
import { UIDropdownActions } from '@ui/dropdowns'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const router = useRouter()

const { start, stop } = useTimeoutFn(() => {
  shown.value = false
}, 100)

const shown = ref(false)

const dataRefId = computed(
  () =>
    `panelMenuItem${props.item.label
      ?.split(' ')
      .map(item => upperFirst(item))
      .join('')}`,
)

const handleClickItem = (value: string, items: NavigationItem[]) => {
  const data = items.find(item => item.key === value)
  if (!data) return
  shown.value = false
  router.push({ name: data.route, params: data.routeParams })
}

const onMouseEnter = () => {
  stop()
  shown.value = true
}

const onMouseLeave = () => {
  start()
}
</script>

<style>
.navbar-item-menu {
  @apply relative;
  @apply py-1 px-0.5;
  @apply rounded-l-md;
  @apply border border-r-0 border-transparent;

  .ui-button-icon__label {
    @apply max-w-none;
  }

  &__caption {
    @apply py-2 px-4;
    @apply text-xs font-medium uppercase;
    @apply text-gray-400;
  }

  &__dropdown {
    .ui-dropdown-list__container {
      @apply !my-0;
    }

    .ui-dropdown-actions-item {
      @apply text-sm;
    }

    .v-popper__inner {
      @apply !h-auto;
    }

    .v-popper__arrow-container {
      @apply visible;
      @apply -translate-y-2;
    }
  }
}
</style>
