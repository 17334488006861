<template>
  <div class="main-desktop-header">
    <div class="main-desktop-header__aside" :class="asideClasses">
      <RouterLink
        :to="{ name: ROUTE_NAME.DASHBOARDS }"
        class="main-desktop-header__logo"
        :class="{ 'main-desktop-header__logo--shrinked': !isNavbarOpened }"
      >
        <AppLogo />
        <span class="main-desktop-header__logo-text">Allposit</span>
      </RouterLink>
      <span v-if="!disabled" class="main-desktop-header__toggler">
        <UIButtonPanel v-model="isNavbarOpened" variant="secondary" />
      </span>
    </div>
    <div
      v-if="repositoryName"
      class="main-desktop-header__repository"
      :class="{ 'main-desktop-header__repository--wide': !isNavbarOpened }"
    >
      <span class="main-desktop-header__repository-name">
        {{ repositoryName }}
      </span>
    </div>
    <div class="main-desktop-header__actions">
      <MainDesktopHeaderNotifications />
      <MainDesktopHeaderSearch v-if="!disabled" />
      <MainDesktopHeaderUser />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { OpenedFlag } from '@types'

import { NAVBAR_OPENED, ROUTE_NAME } from '@/const'

import { useHotKeys } from '@/plugins/hotKeys'

import { useRepositoriesStore } from '@/store/repositories'

import { AppLogo } from '@app'

import MainDesktopHeaderNotifications from './MainDesktopHeaderNotifications.vue'
import MainDesktopHeaderSearch from './MainDesktopHeaderSearch.vue'
import MainDesktopHeaderUser from './MainDesktopHeaderUser.vue'
import { UIButtonPanel } from '@ui/buttons'

type Props = {
  disabled?: boolean
}

const props = defineProps<Props>()

const repositoriesStore = useRepositoriesStore()

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)

const currentRepository = computed(() => repositoriesStore.getCurrentRepository)
const repositoryName = computed(() => currentRepository.value?.name)

const asideClasses = computed(() => ({
  'main-desktop-header__aside--empty': props.disabled,
}))

useHotKeys('navbar', () => {
  if (!isNavbarOpened) return
  isNavbarOpened.value = !isNavbarOpened.value
})
</script>

<style scoped>
.main-desktop-header {
  @apply w-full h-10;
  @apply flex shrink-0 justify-between;
  @apply bg-primary;
  @apply z-20;

  &__aside {
    @apply flex shrink-0;
    @apply transition-all;

    .main-desktop--opened-navbar & {
      @apply w-56;
      @apply justify-between;
      @apply px-4;
      @apply bg-primary-dark;
    }
  }

  &__toggler {
    @apply h-full;
    @apply relative;
    @apply flex items-center justify-center;
    @apply ml-2.5 mr-2;
    @apply transition-all;

    :deep(.ui-button) {
      @apply bg-transparent hover:bg-primary-active;
      @apply border-indigo-600;
      @apply text-indigo-300 hover:text-indigo-100;
      @apply ring-primary-active ring-offset-primary;

      .main-desktop--opened-navbar & {
        @apply text-indigo-100 dark:text-indigo-400 dark:hover:text-indigo-300;
        @apply bg-primary;
        @apply ring-primary ring-offset-primary-dark;
      }
    }

    .main-desktop--opened-navbar & {
      @apply -mr-1.5 ml-0;
    }
  }

  &__logo {
    @apply flex items-center;
    @apply gap-1;

    &--shrinked {
      @apply w-[4.2rem];
      @apply justify-center;
      @apply bg-primary-dark;
    }
  }

  &__logo-text {
    @apply text-primary-selected;
    @apply text-lg font-medium;
  }

  &__logo--shrinked &__logo-text {
    @apply hidden;
  }

  &__repository {
    @apply h-full;
    @apply flex items-center;
    @apply text-white;
    @apply text-sm font-medium;
    @apply transition-all;
    @apply overflow-hidden;

    .main-desktop--opened-navbar & {
      @apply pl-4;
    }

    &--wide {
      @apply !pl-0;
      @apply -ml-0.5;
    }
  }

  &__repository-name {
    @apply truncate;
  }

  &__actions {
    @apply flex flex-auto items-center justify-end;
    @apply pr-6;
    @apply gap-x-2;
  }
}
</style>

<style>
.main-desktop-header-button {
  @apply w-8;
  @apply aspect-square;
  @apply flex items-center justify-center;
  @apply rounded-full;
  @apply hover:bg-primary-active;
  @apply text-indigo-200;
  @apply cursor-pointer;
}

.main-desktop-header-icon {
  @apply w-5 aspect-square;
}
</style>
