<template>
  <UILayoutNoData @click:help="intercom.help(5480500)">
    <template #text-description>
      Get started by creating a new repository
    </template>
    <template #default>
      <UIButton
        :leading="PlusIcon"
        label="Create"
        full
        @click="handleRepositoryCreate"
      />
      <UIButton
        :leading="FolderArrowDownIcon"
        label="Import"
        full
        variant="secondary"
        fill="outlined"
        class="mt-2"
        @click="handleRepositoryImport"
      />
    </template>
    <template #text-question>What is a repository?</template>
  </UILayoutNoData>
</template>

<script setup lang="ts">
import { markRaw } from 'vue'

import { useIntercom } from '@/plugins/intercom'

import { useModalsStore } from '@/store/modals'

import RepositoryCreate from '@/modules/modals/Repositories/Data/RepositoryCreate.vue'
import RepositoryImport from '@/modules/modals/Repositories/Data/RepositoryImport.vue'

import { PlusIcon, FolderArrowDownIcon } from '@heroicons/vue/24/outline'
import { UILayoutNoData } from '@ui/core'
import { UIButton } from '@ui/buttons'

const intercom = useIntercom()
const modalsStore = useModalsStore()

const handleRepositoryCreate = () => {
  const modalInstance = modalsStore.init(
    'repository-create',
    markRaw(RepositoryCreate),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    focusOnLoad: true,
  })
}

const handleRepositoryImport = () => {
  const modalInstance = modalsStore.init(
    'repository-import',
    markRaw(RepositoryImport),
  )
  modalInstance?.open(modalsStore.getZIndex())
}
</script>
