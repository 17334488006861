<template>
  <UISkeletonTree v-if="isLoading" message="Analytics calculating..." />
  <AppTree
    v-else
    v-bind="{
      currentAsset,
      currentPath,
      data,
      formatter,
      getConnectorInfo,
      getDescription,
      repoName,
    }"
    @click:connector="handleClickConnector"
    @click:menu="openEntity"
    @click:name="openEntity"
    @click:path="handleSetPath"
  />
</template>

<script setup lang="ts">
import { computed, markRaw, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import {
  AnalyticsPreparedTree,
  LinkedDataConnectorStatus,
  Repository,
} from '@types'

import { ASSET_FIELD } from '@/entities/assets/utils/const'
import { UNKNOWN_VALUE } from '@/const/common'
import { ROUTE_NAME } from '@/const'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'

import RepositoryPopup from '@/modules/modals/Repositories/Data/RepositoryPopup.vue'
import { AppTree } from '@app'
import { UISkeletonTree } from '@ui/skeletons'

defineOptions({ name: 'MainTree' })

const analyticsStore = useAnalyticsStore()
const assetsBunchStore = useAssetsBunchStore()
const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()

const router = useRouter()

const repositoryModel = ref<Repository>()
const assetId = ref<string>()

const assetsBunch = computed(() => assetsBunchStore.getList)
const data = computed(() => analyticsStore.module.tree.getPreparedData)
const isLoading = computed(
  () =>
    !analyticsStore.getError &&
    (!analyticsStore.module.tree.isInit ||
      analyticsStore.module.tree.isLoading),
)

const repoName = computed(() => repositoriesStore.getCurrentRepositoryName)

const currentAsset = computed(() => repositoryModel.value?.id || assetId.value)

const currentPath = computed(() => analyticsStore.getPath)

const formatter = (key: string) => {
  const asset = assetsBunch.value.get(key)
  return asset?.field<string>(ASSET_FIELD.NAME).value || UNKNOWN_VALUE
}

const getDescription = (key: string) => {
  const asset = assetsBunch.value.get(key)
  return asset?.field<string>(ASSET_FIELD.TICKER).value
}

const getConnectorInfo = (key: string) => {
  const asset = assetsBunch.value.get(key)
  return asset?.isLinked
    ? {
        status: asset?.field<LinkedDataConnectorStatus>(
          ASSET_FIELD.CONNECTOR_STATUS,
        ).value,
        message: asset?.field<string>(ASSET_FIELD.CONNECTOR_STATUS_MESSAGE)
          .value,
      }
    : undefined
}

const handleClickConnector = (item: AnalyticsPreparedTree) => {
  assetId.value = item.path?.at(-1)
  if (!assetId.value) return
  const instance = assetsBunch.value.get(assetId.value)
  instance?.openConnectorInfo(assetId.value, () => {
    assetId.value = undefined
  })
}

const openEntity = (item: AnalyticsPreparedTree) => {
  if (item.path?.length === 1) {
    repositoryModel.value = repositoriesStore.getCurrentRepository
    if (!repositoryModel.value) return
    const modalInstance = modalsStore.init(
      `repository-${repositoryModel.value.id}`,
      markRaw(RepositoryPopup),
    )

    modalInstance?.open(modalsStore.getZIndex(), {
      repository: repositoryModel.value,
    })
  } else {
    assetId.value = item.path?.at(-1)
    if (!assetId.value) return
    const accountId = item.path?.at(-2)
    const instance = assetsBunch.value.get(assetId.value)
    instance?.openDrawer(
      accountId,
      item.amount,
      () => {
        assetId.value = undefined
      },
      item.path,
    )
  }
}

const handleSetPath = (item: AnalyticsPreparedTree, leaf: boolean) => {
  if (!item.path || leaf) return
  router.push({ name: ROUTE_NAME.DASHBOARDS })
  analyticsStore.setPath(item.path)
}

onMounted(() => {
  analyticsStore.setActive('Tree', ['tree'])
})

onUnmounted(() => {
  analyticsStore.setInActive('Tree')
})
</script>
