import { Transaction, TransactionSettings } from '@types'

import { TRANSACTION_ID_PREFIX } from '@/const/common'

import { numberFormat } from '@/helpers/numbers'

export const prepareTransactionAmount = (
  rule: string | undefined,
  amount: number | null,
  unformated?: boolean,
) => {
  const isPercentField = rule === 'percent'
  const amountValue = isPercentField ? (amount || 0) * 100 : amount || 0
  return `${unformated ? amountValue : numberFormat(amountValue)}${
    isPercentField ? '%' : ''
  }`
}

export function getTransactionString(
  transaction: Transaction,
  settingsList: TransactionSettings[],
  getAssetName: (id: string) => string | undefined,
) {
  const { type, entries: transactionEntries } = transaction
  const entries = transactionEntries
  if (!settingsList) return
  const settings = settingsList?.find(item => item.name === type)
  if (!transaction.id?.startsWith(TRANSACTION_ID_PREFIX)) {
    for (let i = 0; i < (settings?.entries?.length || 0); i++) {
      if (!entries[i]) {
        entries[i] = {
          account_id: '',
          amount: null,
          asset_id: '',
        }
      }
    }
  }
  if (!settings?.printString) return
  const result: Record<number, { key?: string; value: string }[]> = {}
  entries.forEach((item, index) => {
    const string = settings.printString[index]
    if (!string) return
    const account = getAssetName(item.account_id) || '...'
    const asset = getAssetName(item.asset_id) || '...'
    const amount = prepareTransactionAmount(
      settings?.entries[index]?.amount?.rule,
      item.amount,
    )
    string.forEach(elem => {
      let key: string | undefined
      let value: string = elem
      switch (elem) {
        case 'account':
          value = account
          key = 'account'
          break
        case 'asset':
          value = asset
          key = 'asset'
          break
        case 'amount':
          value = amount
          key = `amount--${(item.amount || 0) < 0 ? 'negative' : 'positive'}`
          break
      }
      if (!result[index]) {
        result[index] = []
      }
      result[index].push({ key, value })
    })
  })
  return result
}
