<template>
  <div class="main-desktop-tabs">
    <MainDesktopTabsParent v-if="parent" :parent />
    <nav class="main-desktop-tabs__container">
      <MainDesktopTabsArea :items :disabled />
      <div v-if="isAddViewButtonVisible" class="main-desktop-tabs__postfix">
        <AddViewButton>
          <template #default="{ icon, disabled, label, onClick }">
            <UIButton
              :leading="icon"
              :disabled
              :label
              size="xs"
              variant="secondary"
              fill="light"
              hide-label-to-sm
              @click="onClick"
            />
          </template>
        </AddViewButton>
      </div>
    </nav>
    <div data-refid="actionsContainer" class="main-desktop-tabs__actions" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DASHBOARD_NAME } from '@/const'

import { useNavigationStore } from '@/store/navigation'

import AddViewButton from '@/views/Dashboards/components/AddViewButton.vue'
import MainDesktopTabsArea from './MainDesktopTabsArea.vue'
import MainDesktopTabsParent from './MainDesktopTabsParent.vue'
import { UIButton } from '@ui/buttons'

type Props = {
  disabled?: boolean
}

defineProps<Props>()

const navigationStore = useNavigationStore()

const parent = computed(() => navigationStore.getCurrentParent)

const items = computed(() => navigationStore.getCurrentChildNavigation || [])

const isAddViewButtonVisible = computed(() => {
  return parent.value?.label === DASHBOARD_NAME
})
</script>

<style scoped>
.main-desktop-tabs {
  @apply relative;
  @apply flex items-center;
  @apply mb-5 -mt-4 sm:mt-0 -mx-4 sm:-mx-default;
  @apply px-4 sm:px-default;
  @apply border-b border-gray-200 dark:border-gray-600;

  &__container {
    @apply flex flex-auto;
    @apply px-1;
    @apply overflow-hidden;
  }

  &__actions {
    > *:first-child {
      @apply ml-2.5;
    }
  }

  &__postfix,
  &__actions {
    @apply flex items-center;
    @apply gap-2;
  }
}
</style>
