<template>
  <div class="main-mobile-header-user-link group" @click.prevent="handleClick">
    <div class="main-mobile-header-user-link__text">
      {{ item.label }}
    </div>
    <component :is="item.icon" class="main-mobile-header-user-link__icon" />
  </div>
</template>

<script setup lang="ts">
import { DropdownActionsData } from '@types'

type Props = {
  item: DropdownActionsData
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const handleClick = () => {
  props.item.action?.(() => emit('close'))
}
</script>

<style>
.main-mobile-header-user-link {
  @apply flex items-center justify-end;
  @apply gap-2 px-4 py-2;
  @apply hover:bg-gray-100 dark:hover:bg-gray-750;
  @apply text-gray-700 hover:text-gray-900;
  @apply dark:text-gray-300 dark:hover:text-gray-100;
  @apply text-sm;
  @apply cursor-pointer;

  &__icon {
    @apply w-5 h-auto;
  }

  &__text {
    @apply truncate;
  }
}
</style>
