<template>
  <div v-bind="$attrs" @click="handleOpen">
    <Bars3Icon class="main-mobile-footer-icon" aria-hidden="true" />
    <span class="main-mobile-footer-caption">{{ t('More') }}</span>
  </div>
  <Teleport to="body">
    <UIModal
      v-bind="{ isOpen }"
      :type="ModalType.SLIDEPANEL"
      :title="t('More')"
      direction="to-top"
      header-class="main-mobile-footer-more__header"
      wrapper-class="main-mobile-footer-more"
      size="md"
      hide-minimise-button
      with-back
      @hide="handleClose"
    >
      <template #title>
        <MainMobileFooterMoreSearch v-if="!isEmptyApp" />
      </template>
      <template v-if="!isEmptyApp">
        <MoreSection
          v-for="section in navSections"
          :key="section.label"
          :caption="section.label"
        >
          <template #caption="{ caption }">
            <AddViewButton v-if="caption === DASHBOARD_NAME">
              <template #default="{ icon, disabled, label, onClick }">
                <UIButton
                  :leading="icon"
                  :disabled
                  :label
                  size="xs"
                  variant="secondary"
                  :hide-label-to-sm="false"
                  @click="(onClick(), handleClose())"
                />
              </template>
            </AddViewButton>
          </template>
          <MoreSectionNavigation
            :disabled="section.label === DASHBOARD_NAME && isEditMode"
            :parent="section.label"
            @click="handleClose"
          />
        </MoreSection>
      </template>
      <MoreSection
        caption="Profile & settings"
        :items="settingsItems"
        @click="handleClose"
      />
    </UIModal>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, markRaw, ref, watch } from 'vue'

import { ModalType } from '@types'

import { DASHBOARD_NAME, ROUTE_NAME } from '@/const'
import { SettingTabs } from '@/modules/modals/Settings/utils/enums'

import { useLocale } from '@/plugins/i18n'

import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useModalsStore } from '@/store/modals'
import { useNavigationStore } from '@/store/navigation'

import AddViewButton from '@/views/Dashboards/components/AddViewButton.vue'
import MainMobileFooterMoreSearch from './MainMobileFooterMoreSearch.vue'
import ModalsSettings from '@/modules/modals/Settings/Settings.vue'
import MoreSection from './components/MoreSection.vue'
import MoreSectionNavigation from './components/MoreSectionNavigation.vue'
import {
  Bars3Icon,
  Cog6ToothIcon,
  TicketIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline'
import { UIModal } from '@ui/modals'
import { UIButton } from '@ui/buttons'

type Props = {
  isEmptyApp?: boolean
}

defineProps<Props>()

const { t } = useLocale('layouts.Main')

const dashboardSettingsStore = useDashboardsSettingsStore()
const modalsStore = useModalsStore()
const navigationStore = useNavigationStore()

const isOpen = ref(false)

const navSections = computed(() => navigationStore.getParentNavigation)

const settingsItems = computed(() => [
  ...Object.values(SettingTabs).map(tab => ({
    icon: tab === SettingTabs.ACCOUNT ? UserCircleIcon : Cog6ToothIcon,
    label: tab,
    action: () => {
      const modalInstance = modalsStore.init(
        'settings',
        markRaw(ModalsSettings),
      )
      modalInstance?.open(modalsStore.getZIndex(), {
        tab,
      })
    },
  })),
  {
    icon: TicketIcon,
    label: 'Subscription',
    route: ROUTE_NAME.SUBSCRIPTION,
  },
])

const isEditMode = computed(() => dashboardSettingsStore.getEditMode)

const handleOpen = () => {
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
}

watch(isEditMode, value => {
  if (!value) return
  handleClose()
})
</script>

<style>
.main-mobile-footer-more {
  @apply pb-safe-offset-4;

  &__header {
    @apply gap-2;
  }
}
</style>
