<template>
  <UICard title="Profile info">
    <div class="account-profile__item">Email: {{ email }}</div>
    <div v-if="connectedAccounts?.length" class="account-profile__item">
      Connected accounts: {{ connectedAccountsString }}
    </div>
  </UICard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UICard } from '@ui/core'

import { useUserStore } from '@/store/user'

const userStore = useUserStore()
const email = computed(() => userStore.getEmail)
const connectedAccounts = computed(() => userStore.getConnectedAccounts)

const connectedAccountsString = computed(() => {
  if (!connectedAccounts.value) return ''
  return connectedAccounts.value.map(acc => acc.providerName).join(', ')
})
</script>

<style>
.account-profile {
  &__item {
    @apply mb-2;
    @apply text-sm font-medium;
    @apply text-gray-500;
    @apply truncate;
  }
}
</style>
