<template>
  <FlowsForm
    :key="flowsFormKey"
    v-bind="{ defaultData, restrictions }"
    @submit="handleSubmit"
  />
  <UIList
    v-if="items?.length"
    v-bind="{ columns, items }"
    class="private-fund-flows"
    @remove="handleRemove"
  />
  <UILayoutNoData v-else inline hide-question-icon class="flex-auto" />
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, ref } from 'vue'

import { Transaction, TransactionClass } from '@types'

import {
  TRANSACTION_ENTRY_FIELD,
  TRANSACTION_ENTRY_FIELD_LABEL,
  TRANSACTION_FIELD,
  TRANSACTION_FIELD_LABEL,
} from '@/const'

import { numberFormat } from '@/helpers/numbers'
import { getCurrentDate, stringToLocalDateString } from '@/helpers/dates'

import { useRepositoriesStore } from '@/store/repositories'

import FlowsForm from './FlowsForm.vue'
import { UILayoutNoData, UIList } from '@ui/core'

type Props = {
  flows: TransactionClass[]
  restrictions: string[]
}

type Emit = {
  remove: [index: number]
  submit: [data: TransactionClass]
}

const props = defineProps<Props>()
const emit = defineEmits<Emit>()

defineOptions({ name: 'PrivateFundFlows' })

const repositoriesStore = useRepositoriesStore()

const defaultDate = inject<ComputedRef<string | undefined>>('defaultDate')

const flowsFormKey = ref(0)
const defaultType = ref(props.restrictions[0])

const columns = computed(() => [
  {
    name: TRANSACTION_FIELD.DATE,
    caption: TRANSACTION_FIELD_LABEL[TRANSACTION_FIELD.DATE],
    content: 'max-content',
    formatter: (value: string) => `${stringToLocalDateString(value)}`,
  },
  {
    name: TRANSACTION_FIELD.TYPE,
    caption: TRANSACTION_FIELD_LABEL[TRANSACTION_FIELD.TYPE],
    content: 'auto',
  },
  {
    name: TRANSACTION_ENTRY_FIELD.AMOUNT,
    caption: TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.AMOUNT],
    content: 'max-content',
    headerClass: 'text-right',
    cellClass: 'justify-end',
    formatter: (value: number) => numberFormat(value),
  },
])

const items = computed(() =>
  props.flows.map(item => ({
    [TRANSACTION_FIELD.DATE]: item.field(TRANSACTION_FIELD.DATE).value,
    [TRANSACTION_FIELD.TYPE]: item.field(TRANSACTION_FIELD.TYPE).value,
    [TRANSACTION_ENTRY_FIELD.AMOUNT]:
      item.entries[1][TRANSACTION_ENTRY_FIELD.AMOUNT],
  })),
)

const repositoryId = computed(() => repositoriesStore.getCurrentRepository?.id)

const defaultData = computed<Transaction>(() => ({
  id: '',
  date: defaultDate?.value || getCurrentDate({}),
  type: defaultType.value,
  entries: [
    {
      account_id: '',
      amount: null,
      asset_id: '',
    },
    {
      account_id: '',
      amount: null,
      asset_id: '',
    },
  ],
  repository_id: `${repositoryId.value}`,
  description: '',
}))

const handleSubmit = (data: TransactionClass) => {
  emit('submit', data)
  defaultType.value = data.field<string>(TRANSACTION_FIELD.TYPE).value
  flowsFormKey.value++
}

const handleRemove = (index: number) => {
  emit('remove', index)
}
</script>

<style>
.private-fund-flows {
  @apply max-h-[21.5rem];
  @apply overflow-y-auto;
}
</style>
