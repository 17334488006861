import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Settings as luxonSettings } from 'luxon'

import App from '@/App.vue'

import router from '@/router'

import { getAppMode } from './helpers/common'

import circularTab from '@/directives/circularTab'

import { i18nInit } from '@/plugins/i18n'
import intercom from '@/plugins/intercom'
import notifications from '@/plugins/notification'
import { tooltip } from '@/plugins/tooltip'

import amplifyInit from '@/services/auth'
import { faroInit } from '@/services/faro'
import useWSService from '@/services/ws'

import '@/assets/styles/index.css'

const {
  VITE_APP_APP_CLIENT_ID,
  VITE_APP_AWS_REGION,
  VITE_APP_FARO_URL,
  VITE_APP_FARO_SECRET,
  VITE_APP_OAUTH_DOMAIN,
  VITE_APP_USER_POOL_ID,
  VITE_APP_WS_URL,
} = import.meta.env

luxonSettings.defaultLocale = 'en'

// config an instance of amplify for user authentication
amplifyInit(
  VITE_APP_USER_POOL_ID,
  VITE_APP_APP_CLIENT_ID,
  VITE_APP_AWS_REGION,
  VITE_APP_OAUTH_DOMAIN,
)

// init and config an i18n instance to support languages
i18nInit()

// init and config an instance of faro to detect errors
VITE_APP_FARO_SECRET &&
  faroInit(VITE_APP_FARO_URL, VITE_APP_FARO_SECRET, getAppMode(__APP_MODE__))

// init a websockets instance
const ws = __IS_WS_DISABLED__ ? undefined : useWSService(VITE_APP_WS_URL)

// create and config a vue app
const app = createApp(App)

app.use(router)
app.use(notifications)
app.use(createPinia())
app.use(intercom)

app.provide('ws', ws)

app.directive('tooltip', tooltip)
app.directive('circular-tab', circularTab)

app.mount('#app')
