<template>
  <form class="init-app-modal-form" method="POST" @submit.prevent="onSubmit">
    <div class="init-app-modal-form__qr">
      <UIPreloader v-if="!verificationCode" />
      <QrCode v-else :value="qrUri" :size="200" />
    </div>
    <UIInputText
      v-model="codeValue"
      :error="codeError"
      :disabled="isFieldDisabled"
      label="Enter your verification code from app"
    />
  </form>
  <UIStepper
    v-model="currentStep"
    :count="2"
    :disabled="loading"
    :loading
    next-label="Verify and complete"
    previous-label="Previous step"
    @prev="emit('prev')"
    @next="onSubmit"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import QrCode from 'qrcode.vue'

import { rules } from '@/helpers/validate'

import { useUserStore } from '@/store/user'

import { UIPreloader, UIStepper } from '@ui/core'
import { UIInputText } from '@ui/fields'

type Emits = {
  (e: 'prev'): void
  (e: 'next'): void
}

const emit = defineEmits<Emits>()

const currentStep = defineModel<number>()

const { handleSubmit } = useForm({
  initialValues: {
    code: '',
  },
  validationSchema: {
    code: rules.required,
  },
})

const { value: codeValue, errorMessage: codeError } = useField<string>('code')

const userStore = useUserStore()

const loading = ref(false)
const verificationCode = ref<string>()

const qrUri = computed(() => {
  return `otpauth://totp/Allposit:${userStore.getEmail}?secret=${verificationCode.value}&issuer=Allposit`
})

const isFieldDisabled = computed(() => loading.value || !verificationCode.value)

const onSubmit = handleSubmit(async values => {
  loading.value = true
  if (await userStore.verifyTOTP(values.code)) {
    emit('next')
  }
  loading.value = false
})

const fetchVerificationCode = async () => {
  verificationCode.value = await userStore.setupTOTP()
}

onMounted(fetchVerificationCode)
</script>

<style>
.init-app-modal-form {
  @apply flex flex-col gap-6;

  &__qr {
    @apply flex justify-center;
  }
}
</style>
