<template>
  <UICard title="Logout After Inactive For" class="settings-common-inactivity">
    <UIInputSelect v-model="value" :data />
  </UICard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { DropdownListData } from '@types'

import { DEFAULT_INACTIVITY_DELAY } from '@/plugins/inactivity'

import { useUserSettingsStore } from '@/store/user/settings'

import { UICard } from '@ui/core'
import { UIInputSelect } from '@ui/fields'

const MIN_VALUE = 5
const MAX_VALUE = 60
const STEP_VALUE = 5

const userSettingsStore = useUserSettingsStore()

const internalValue = ref()

const data = computed(() => {
  const result: DropdownListData[] = []
  if (!__IS_PROD__) {
    result.push({ value: '1', label: '1 minute' })
  }
  for (let value = MIN_VALUE; value <= MAX_VALUE; value += STEP_VALUE) {
    result.push({ value: value.toString(), label: `${value} minutes` })
  }
  return result
})

const value = computed({
  get() {
    return (
      internalValue.value ??
      (userSettingsStore.getAllowedInactivityTime || DEFAULT_INACTIVITY_DELAY)
    ).toString()
  },
  set(value) {
    internalValue.value = value
    userSettingsStore.setAllowedInactivityTime(+value)
  },
})
</script>

<style>
.settings-common-inactivity {
  .ui-input-select {
    @apply max-w-44;
  }
}
</style>
