<template>
  <div v-if="isSwiping" class="app-reload-page" :style="mainStyles">
    <template v-if="willReload">
      <SpinnerIcon class="app-reload-page__spinner" />
      <span>Reloading the page...</span>
    </template>
    <template v-else>
      <span>Pull down to reload</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { usePointerSwipe } from '@vueuse/core'

import { RELOAD_DISTANCE, START_DISTANCE } from './utils/const'

import SpinnerIcon from '@/assets/images/icons/spinner.svg'

const { isSwiping, distanceY } = usePointerSwipe(document.body, {
  threshold: START_DISTANCE,
  pointerTypes: ['touch', 'pen'],
})

const distance = computed(() =>
  distanceY.value > 0 ? 0 : Math.abs(distanceY.value),
)

const mainStyles = computed(() => {
  return {
    height: `${distance.value}px`,
  }
})

const willReload = computed(() => distance.value > RELOAD_DISTANCE)

const setHeaderOffset = (value: number) => {
  const header = document.querySelector('.main-mobile-header')
  if (!header) return
  if (value) {
    header.setAttribute(
      'style',
      `padding-top:0.5rem;transform:translateY(${value}px)`,
    )
  } else {
    header.removeAttribute('style')
  }
}

watch(isSwiping, value => {
  if (value) return
  setHeaderOffset(0)
  if (distance.value > RELOAD_DISTANCE) {
    window.location.reload()
  }
})

watch(distance, value => {
  if (!isSwiping.value) return
  setHeaderOffset(value)
})
</script>

<style>
.app-reload-page {
  @apply flex items-center justify-center;
  @apply gap-2 pt-safe-offset-2 pb-2;
  @apply bg-primary;
  @apply text-indigo-400;
  @apply z-[10000];

  &__spinner {
    @apply size-7;
    @apply animate-spin;
  }
}
</style>
