<template>
  <UICard
    title="Two-Factor Authentication (2FA)"
    description="Ensure top-level security for your account with Two-Factor Authentication."
  >
    <div class="account-security">
      <div class="account-security__info">
        <span class="account-security__title">Authentication App (TOTP)</span>
        <span class="account-security__description"
          >Use an app to log in with time-based one-time codes.</span
        >
      </div>
      <UIButton v-if="!isMFAEnabled" label="Turn on" @click="handleTOTPon" />
      <UIButton
        v-else
        label="Turn off"
        :loading
        :disabled="loading"
        variant="danger"
        @click="handleTOTPoff"
      />
    </div>
  </UICard>
</template>

<script setup lang="ts">
import { computed, markRaw, ref } from 'vue'

import { useModalsStore } from '@/store/modals'
import { useUserStore } from '@/store/user'

import InitAppModal from './components/InitAppModal.vue'

import { UICard } from '@ui/core'
import { UIButton } from '@ui/buttons'

const userStore = useUserStore()
const modalsStore = useModalsStore()

const loading = ref(false)

const isMFAEnabled = computed(() => userStore.isMFAEnabled)

const handleTOTPon = async () => {
  const modalInstance = modalsStore.init(
    'settings-init-app',
    markRaw(InitAppModal),
  )
  modalInstance?.open(modalsStore.getZIndex(), {})
}

const handleTOTPoff = async () => {
  loading.value = true
  await userStore.disableTOTP()
  loading.value = false
}
</script>

<style scoped>
.account-security {
  @apply flex justify-between items-center;

  &__info {
    @apply flex flex-col;
  }

  &__title {
    @apply text-gray-950 dark:text-gray-50;
    @apply text-base whitespace-nowrap;
  }

  &__description {
    @apply text-gray-500 dark:text-gray-400;
    @apply text-xs;
  }
}
</style>
