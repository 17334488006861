<template>
  <StepInvestment :transaction :asset>
    <template #start>
      <div class="private-fund-investment">
        <UIYesNoSwitch
          v-model="isNoDrawdown"
          label="Drawdown?"
          variant="secondary"
          reverse
        />
        <component
          :is="transaction.getFormAmount2()"
          v-if="!isNoDrawdown"
          label="Commitment"
          :instance="transaction"
          hide-sign
          trail-currency
        />
      </div>
    </template>
    <template #amount>&nbsp;</template>
  </StepInvestment>
</template>

<script setup lang="ts">
import { AssetClass, TransactionClass } from '@types'

import StepInvestment from '../modules/StepInvestment.vue'
import { UIYesNoSwitch } from '@ui/controllers'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
}

defineProps<Props>()

defineOptions({ inheritAttrs: false })

const isNoDrawdown = defineModel<boolean>('isNoDrawdown', { required: true })
</script>

<style>
.private-fund-investment {
  @apply grid grid-cols-[1fr_1.3fr];
  @apply gap-4;
}
</style>
