<template>
  <UIDottedMenu
    v-if="menuMode && items.length"
    :items
    size="xxs"
    :meta="{ key: item.key }"
  />
  <UIDropdownActions
    v-else-if="!menuMode"
    v-model="isShown"
    :data
    :disabled="isDropdownDisabled"
    placement="bottom"
    popper-class="main-view-tab-dropdown__popper"
  >
    <template #default="{ toggle }">
      <span
        class="main-view-tab-dropdown__caption"
        :class="textClasses"
        @click="toggle"
      >
        <slot v-bind="{ disabled }" />
      </span>
    </template>
  </UIDropdownActions>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { DottedMenuItem, NavigationItem } from '@types'

import { UIDottedMenu } from '@ui/core'
import { UIDropdownActions } from '@ui/dropdowns'
import { PencilIcon } from '@heroicons/vue/24/outline'

type Props = {
  item: NavigationItem
  disabled?: boolean
  container?: string
  menuMode?: boolean
}

type Emits = {
  edit: []
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  show: () => {
    isShown.value = true
  },
  hide: () => {
    isShown.value = false
  },
})

const isShown = ref(false)

const isDropdownDisabled = computed(() => props.disabled || !items.value.length)

const textClasses = computed(() => ({
  'main-view-tab-dropdown__caption--active':
    props.item.active && items.value.length,
  'main-view-tab-dropdown__caption--disabled': props.disabled,
}))

const items = computed(() => {
  const result: DottedMenuItem[] = []
  props.item.editable &&
    result.push({
      tooltip: 'Rename',
      leading: PencilIcon,
      variant: 'gray',
      fill: 'light',
      action: ({ callback }) => {
        emit('edit')
        callback?.()
      },
    })
  props.item.actions && result.push(...props.item.actions)
  return result
})

const data = computed(() => {
  return items.value.map(item => ({
    label: `${item.tooltip}`,
    icon: item.leading,
    action: (hide: () => void) =>
      item.action?.({ callback: hide, key: props.item.key }),
    variant: item.variant,
  }))
})
</script>

<style>
.main-view-tab-dropdown {
  @apply min-w-32;
  @apply py-1;

  &__caption {
    @apply rounded-sm;

    &--active:not(&--disabled) {
      @apply hover:outline hover:outline-1 hover:outline-offset-2;
      @apply hover:outline-indigo-100 hover:dark:outline-gray-600;
      @apply cursor-pointer;
    }
  }

  &__text {
    @apply text-xs;
  }

  &__icon {
    @apply w-3 aspect-square;
  }

  &__popper {
    .ui-dropdown-list__container {
      @apply my-0;
    }

    .v-popper__arrow-container {
      @apply visible;
    }
  }
}
</style>
