<template>
  <div class="main-mobile">
    <MainWrapper>
      <template
        #default="{
          isEmptyApp,
          isEmptyAnalytics,
          isEmptyConnectors,
          isWelcomeScreenVisible,
        }"
      >
        <MainMobileHeader :disabled="isEmptyApp" />
        <AppAlerts />
        <AppAnalyticsProgressBar />
        <main class="main-mobile__content">
          <AppWelcomeScreen
            v-if="isWelcomeScreenVisible"
            v-bind="{ isEmptyApp, isEmptyAnalytics, isEmptyConnectors }"
          />
          <router-view v-else v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </main>
        <MainMobileFooter :is-empty-app />
      </template>
    </MainWrapper>
  </div>
</template>

<script setup lang="ts">
import { provide } from 'vue'
import { AppAlerts, AppAnalyticsProgressBar, AppWelcomeScreen } from '@app'

import { NAVBAR_OPENED } from './utils/const'

import MainMobileHeader from './MainMobileHeader.vue'
import MainMobileFooter from './MainMobileFooter.vue'
import MainWrapper from './components/Wrapper.vue'

provide(NAVBAR_OPENED, false)
</script>

<style scoped>
.main-mobile {
  @apply h-screen min-w-[360px];
  @apply pt-safe-offset-12 pb-safe-offset-[4.25rem];
  @apply flex flex-col;

  &__content {
    @apply flex flex-col flex-auto;
    @apply px-4 sm:px-6 pt-0.5;
    @apply overflow-hidden;
  }
}
</style>
