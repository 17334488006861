<template>
  <UIButton
    v-if="!codeRequested"
    label="Set password"
    :disabled="loading"
    :loading="loading"
    @click="handleSetPassword"
  />
  <form v-else method="POST" @submit.prevent="onSubmit">
    <UICard
      title="Set Password"
      description="We've sent you a code to your email address"
    >
      <div class="settings-account-set-password">
        <UIInputText
          v-model="codeValue"
          :error="codeError"
          label="Code"
          :disabled="loading"
          autocomplete="one-time-code"
          @ready="(e: HTMLInputElement) => e.focus()"
        />
        <UIInputPassword
          v-model="newPasswordValue"
          :error="newPasswordError"
          label="Enter new password"
          @click:enter="onSubmit"
        />
        <UIPasswordValidation
          v-model="isPasswordValid"
          :password="newPasswordValue"
        />
      </div>
      <template #footer>
        <UIButton
          label="Set password"
          :loading="loading"
          :disabled="loading || !meta.valid || !isPasswordValid"
        />
      </template>
    </UICard>
  </form>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useField, useForm } from 'vee-validate'

import { rules } from '@/helpers/validate'

import { useUserStore } from '@/store/user'

import { UIButton } from '@ui/buttons'
import { UICard, UIPasswordValidation } from '@ui/core'
import { UIInputPassword, UIInputText } from '@ui/fields'

const userStore = useUserStore()

const loading = computed(() => userStore.loading)
const email = computed(() => userStore.getEmail)
const codeRequested = ref(false)
const isPasswordValid = ref(false)

const { handleSubmit, meta } = useForm({
  initialValues: {
    confirmationCode: '',
    newPassword: '',
  },
  validationSchema: {
    confirmationCode: rules.required,
    newPassword: rules.required,
  },
})

const { value: codeValue, errorMessage: codeError } =
  useField<string>('confirmationCode')
const { value: newPasswordValue, errorMessage: newPasswordError } =
  useField<string>('newPassword')

const handleSetPassword = async () => {
  if (!email.value) return
  codeRequested.value = await userStore.forgotPassword({
    username: email.value,
  })
}

const onSubmit = handleSubmit(async values => {
  if (!email.value) return
  await userStore.changePassword({
    username: email.value,
    ...values,
  })
  userStore.setPasswordProvided()
})
</script>

<style>
.settings-account-set-password {
  @apply flex flex-col gap-6;

  .password-validation {
    @apply -mt-4;
  }
}
</style>
