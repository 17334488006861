<template>
  <form method="POST" @submit.prevent="onSubmit">
    <UICard
      title="Password"
      description="You can change your password by filling out the form below."
    >
      <div class="settings-account-password">
        <UIInputPassword
          v-model="oldPasswordValue"
          :error="oldPasswordError"
          label="Enter your current password"
        />
        <UIInputPassword
          v-model="newPasswordValue"
          :error="newPasswordError"
          label="Enter new password"
        />
      </div>
      <UIPasswordValidation
        v-model="isPasswordValid"
        :password="newPasswordValue"
      />
      <template #footer>
        <UIButton
          label="Change password"
          :loading="loading"
          :disabled="loading || !meta.valid || !isPasswordValid"
        />
      </template>
    </UICard>
  </form>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useField, useForm } from 'vee-validate'

import { rules } from '@/helpers/validate'

import { useUserStore } from '@/store/user'

import { UIButton } from '@ui/buttons'
import { UICard, UIPasswordValidation } from '@ui/core'
import { UIInputPassword } from '@ui/fields'

const userStore = useUserStore()

const isPasswordValid = ref(false)
const loading = computed(() => userStore.loading)

const { handleSubmit, meta } = useForm({
  initialValues: {
    oldPassword: '',
    newPassword: '',
  },
  validationSchema: {
    oldPassword: rules.required,
    newPassword: rules.required,
  },
})

const { value: oldPasswordValue, errorMessage: oldPasswordError } =
  useField<string>('oldPassword')
const { value: newPasswordValue, errorMessage: newPasswordError } =
  useField<string>('newPassword')

const onSubmit = handleSubmit(async values => {
  await userStore.newPassword(values)
})
</script>

<style>
.settings-account-password {
  @apply flex flex-col gap-6;

  .password-validation {
    @apply -mt-4;
  }
}
</style>
