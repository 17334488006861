export const STEPS_LIST = [
  {
    name: 'Investing or Holding Entity',
  },
  {
    name: 'Investment',
  },
  {
    name: 'Valuation',
    description: 'optional',
  },
  {
    name: 'Asset tags',
  },
  {
    name: 'Summary',
  },
]

export const TRANSACTION_TYPE = 'subscribe and fund'

export const ASSET_TYPE_EXCLUDES = ['private fund', 'fine art and collectables']

export const ASSET_CLASS_LIST = ['Alternative Investments', 'Life Insurance']

export const INVESTMENT_STRATEGY_LIST = [
  'Private Equity',
  'Private Credit',
  'Growth & Venture',
  'Life Insurance',
]
