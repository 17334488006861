<template>
  <div
    class="main-desktop-tabs-primary-item-label"
    @contextmenu="handleContextMenu"
  >
    <InputString v-if="isEditMode" v-model="labelValue" @save="handleSave" />
    <template v-else>
      <ViewTabDropdown
        ref="dropdownRef"
        v-bind="{ item, disabled }"
        @edit="isEditMode = true"
      >
        {{ item.label }}
      </ViewTabDropdown>
      <span v-if="isDirty" class="main-desktop-tabs-primary-item-label__dirty">
        *
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue'

import { NavigationItem } from '@types'

import InputString from './components/InputString.vue'
import ViewTabDropdown from './components/ViewTabDropdown.vue'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const dropdownRef = useTemplateRef('dropdownRef')

const isEditMode = ref(false)
const labelValue = ref(props.item.label || '')

const isDirty = computed(() => props.item?.isDirty)

const handleSave = () => {
  props.item.update?.(labelValue.value)
  isEditMode.value = false
}

const handleContextMenu = (event: MouseEvent) => {
  event.preventDefault()
  if (props.disabled) return
  dropdownRef.value?.show()
}
</script>

<style>
.main-desktop-tabs-primary-item-label {
  @apply relative;

  &__dirty {
    @apply absolute bottom-0.5 left-full;
    @apply text-yellow-500;
  }
}
</style>
