<template>
  <div class="wizzard__form">
    <slot name="start" />
    <component
      :is="asset.getFormName()"
      :focus-on-load="focusNameOnLoad"
      :instance="asset"
      label="Name"
      :placeholder="`Ex. ${placeholder}`"
    />
    <div class="wizzard-step-investment__grid">
      <component
        v-bind="{ restrictions }"
        :is="asset.getFormType()"
        :focus-on-load="focusTypeOnLoad"
        :instance="asset"
        :readonly="restrictions?.length === 1"
        label="Type"
      />
      <component
        :is="asset.getFormCurrency()"
        :instance="asset"
        label="Currency"
        class="wizzard-step-investment__currency"
      />
    </div>
    <div class="wizzard-step-investment__grid">
      <component
        :is="transaction.getFormDate()"
        :instance="transaction"
        label="Date of investment"
      />
      <slot name="amount">
        <component
          :is="transaction.getFormAmount2()"
          :label
          :instance="transaction"
        />
      </slot>
    </div>
    <component
      :is="transaction.getFormDescription()"
      :instance="transaction"
      label="Description"
      placeholder="Optional"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { AssetClass, TransactionClass } from '@types'

import { ASSET_FIELD } from '@/const'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const assetRestrictions = inject<string[]>('assetRestrictions')
const assetExcludes = inject<string[]>('assetExcludes')

const restrictions = computed(
  () =>
    assetRestrictions ||
    props.transaction.settings?.entries[0].asset?.includes?.filter(
      item => !assetExcludes || !assetExcludes?.includes(item),
    ),
)

const label = inject<string>('assetCostLabel', 'Cost')
const placeholder = inject<string>('assetNamePlaceholder', 'Vintage 2023')

const focusNameOnLoad = computed(
  () => !props.asset.field(ASSET_FIELD.NAME).value,
)

const focusTypeOnLoad = computed(
  () => !focusNameOnLoad.value && !props.asset.field(ASSET_FIELD.TYPE).value,
)
</script>

<style>
.wizzard-step-investment {
  &__grid {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-4;
  }
  &__currency {
    @apply opacity-50 focus-within:opacity-100 transition-opacity duration-300;
  }
}
</style>
