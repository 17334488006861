<template>
  <div class="app-breadcrumb">
    <UIDropdownList
      :data
      :current-value
      placement="bottom-start"
      popper-class="app-breadcrumb__dropdown"
      @click:item="handleClickItem"
    >
      <template #default="{ toggle }">
        <UIButtonIcon
          v-tooltip.top="'Filter analytics'"
          :leading="FunnelIcon"
          variant="secondary"
          size="xxs"
          @click="toggle"
        />
      </template>
    </UIDropdownList>
    <div class="app-breadcrumb__current">{{ displayValue }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy, sortedUniqBy } from 'lodash'

import { ANALYTICS_REPO_ROOT, ASSET_FIELD, UNKNOWN_VALUE } from '@/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'

import { UIButtonIcon } from '@ui/buttons'
import { UIDropdownList } from '@ui/dropdowns'
import { FunnelIcon } from '@heroicons/vue/24/outline'

type Props = {
  items: string[][]
  path: string[]
}

type Emits = {
  'click:item': [data: string[]]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'AppBreadcrumb',
})

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()

const inputItems = computed(
  () =>
    props.items?.map(item => ({
      value: JSON.stringify(item),
      label: getAssetName(item.at(-1)),
    })) || [],
)

const data = computed(() =>
  sortedUniqBy(
    orderBy(
      inputItems.value,
      item =>
        item.label === repositoriesStore.getCurrentRepositoryName || item.label,
    ),
    item => item.label.at(-1),
  ),
)

const displayValue = computed(() => getAssetName(props.path.at(-1)))

const currentValue = computed(() => {
  const currentNode = props.path.at(-1)
  if (!currentNode) return
  return inputItems.value.find(
    item => JSON.parse(item.value).at(-1) === currentNode,
  )?.value
})

const getAssetName = (id?: string) => {
  if (!id) return UNKNOWN_VALUE
  if (id === ANALYTICS_REPO_ROOT)
    return repositoriesStore.getCurrentRepositoryName || UNKNOWN_VALUE
  const asset = assetsBunchStore.getElementById(id)
  return asset?.field<string>(ASSET_FIELD.NAME).value || UNKNOWN_VALUE
}

const handleClickItem = (value: string) => {
  if (value === JSON.stringify(props.path)) return
  emit('click:item', JSON.parse(value))
}
</script>

<style>
.app-breadcrumb {
  @apply flex flex-auto items-center;
  @apply gap-2;

  &__current {
    @apply flex-auto;
    @apply text-sm;
    @apply truncate;
  }

  &__dropdown {
    .v-popper__inner {
      @apply min-w-56;
    }
  }
}
</style>
