<template>
  <div class="wizzard__form">
    <div class="real-estate-step-2__grid">
      <component
        :is="transaction.getFormDate()"
        :instance="transaction"
        label="Date of purchase"
      />
      <div class="real-estate-step-2__cost">
        <component
          v-bind="{ focusOnLoad }"
          :is="transaction.getFormAmount2()"
          :instance="transaction"
          label="Cost"
          hide-sign
          trail-currency
          focus-on-load
        />
        <component
          :is="asset.getFormCurrency()"
          :instance="asset"
          label="Currency"
          class="real-estate-step-2__currency"
        />
      </div>
    </div>
    <component
      :is="transaction.getFormDescription()"
      :instance="transaction"
      label="Description"
      placeholder="Optional"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetClass, TransactionClass } from '@types'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const focusOnLoad = computed(() => !props.transaction.entries[1]?.amount)
</script>

<style>
.real-estate-step-2 {
  &__grid {
    @apply grid grid-cols-1 sm:grid-cols-[0.5fr_1fr] gap-4;
  }
  &__cost {
    @apply grid grid-cols-[1fr_0.5fr] gap-4;
  }
  &__currency {
    @apply opacity-50 focus-within:opacity-100 transition-opacity duration-300;
  }
}
</style>
