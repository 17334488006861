<template>
  <div class="init-app-modal-intro__description">
    You'll need to install an authentication app on your phone to set up
    Two-Factor Authentication. We recommend one of the following apps.
  </div>
  <div class="init-app-modal-intro__services">
    <div
      v-for="service in services"
      :key="service.name"
      class="init-app-modal-intro__service"
    >
      <component
        :is="service.icon"
        aria-hidden="true"
        class="init-app-modal-intro__service-icon"
      />
      <span class="init-app-modal-intro__service-name">
        {{ service.name }}
      </span>
    </div>
  </div>
  <UIStepper
    v-model="currentStep"
    :count="2"
    next-label="I am ready to scan"
    previous-label="Back to settings"
    @prev="emit('prev')"
    @next="emit('next')"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import AuthyIcon from '@/assets/images/icons/authy.svg'
import DuoIcon from '@/assets/images/icons/duo.svg'
import GoogleIcon from '@/assets/images/icons/google-auth.svg'
import MicrosoftIcon from '@/assets/images/icons/microsoft-auth.svg'

import { UIStepper } from '@ui/core'

type Emits = {
  (e: 'prev'): void
  (e: 'next'): void
}

const emit = defineEmits<Emits>()

const currentStep = defineModel<number>()

const services = computed(() => [
  {
    icon: AuthyIcon,
    name: 'Authy',
  },
  {
    icon: DuoIcon,
    name: 'Duo Mobile',
  },
  {
    icon: GoogleIcon,
    name: 'Google Authenticator',
  },
  {
    icon: MicrosoftIcon,
    name: 'Microsoft Authenticator',
  },
])
</script>

<style>
.init-app-modal-intro {
  &__description {
    @apply text-sm text-gray-500 dark:text-gray-400;
    @apply text-center;
  }

  &__services {
    @apply grid grid-cols-2 lg:grid-cols-2;
    @apply gap-0.5 mt-8 mb-4 lg:mt-0;
  }

  &__service {
    @apply flex justify-start items-center;
    @apply gap-4 py-4 px-4;
    @apply bg-gray-50 dark:bg-gray-850;
  }

  &__service-icon {
    @apply shrink-0;
    @apply size-12;
  }

  &__service-name {
    @apply text-sm text-left;
  }
}
</style>
