<!-- eslint-disable vue/no-v-html -->
<template>
  <input
    ref="inputRef"
    v-model="modelValue"
    type="text"
    class="main-input-string"
    :style="mainStyles"
    @keydown.enter="handleEnter"
    @blur="handleSubmit"
    @click.stop
  />
  <div ref="textRef" class="main-input-string__text" v-html="textValue" />
</template>

<script setup lang="ts">
import { computed, onMounted, nextTick, useTemplateRef } from 'vue'
import { useElementBounding } from '@vueuse/core'

type Emits = {
  save: [data: string]
}

const emit = defineEmits<Emits>()

const modelValue = defineModel<string>({ required: true })

const inputRef = useTemplateRef('inputRef')
const textRef = useTemplateRef('textRef')

const { width } = useElementBounding(textRef)

const textValue = computed(() => {
  return modelValue.value.replace(/ /g, '&nbsp;')
})

const mainStyles = computed(() => ({
  width: `${width.value}px`,
}))

const handleEnter = () => {
  inputRef.value?.blur()
}

const handleSubmit = () => {
  emit('save', modelValue.value)
}

onMounted(async () => {
  await nextTick()
  inputRef.value?.focus()
})
</script>

<style>
.main-input-string {
  &:is(input) {
    @apply min-w-[1ch];
    @apply p-0;
    @apply bg-transparent;
    @apply border-none !ring-0;
    @apply shadow-none;
    @apply text-sm;
  }

  &__text {
    @apply absolute;
    @apply invisible;
    @apply text-sm;
    @apply pointer-events-none;
  }
}
</style>
