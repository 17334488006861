<template>
  <div v-if="instance" class="private-fund-flows-form">
    <div class="private-fund-flows-form__input">
      <component
        :is="instance.getFormDate()"
        v-bind="{ instance }"
        tabindex="0"
      />
    </div>
    <div class="private-fund-flows-form__input">
      <component
        :is="instance.getFormType()"
        placeholder="Select type"
        v-bind="{ instance, restrictions }"
        tabindex="1"
      />
    </div>
    <div class="private-fund-flows-form__input">
      <component
        :is="instance.getFormAmount2()"
        v-if="type"
        :placeholder="`Amount in ${currency}`"
        v-bind="{ instance }"
        focus-on-load
        tabindex="2"
        prefix=""
        @click:enter="handleSubmit"
      />
      <UIInputText v-else :placeholder="`Amount in ${currency}`" disabled />
    </div>
    <UIButton
      v-bind="{
        disabled: isButtonDisabled,
        label: 'Add',
      }"
      size="sm"
      tabindex="3"
      class="private-fund-flows-form__button"
      @click="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, onMounted, ref } from 'vue'

import { Transaction, TransactionClass } from '@types'

import { TRANSACTION_FIELD } from '@/const'

import { UIButton } from '@ui/buttons'
import { UIInputText } from '@ui/fields'

type Props = {
  defaultData: Transaction
  restrictions: string[]
}

type Emits = {
  submit: [data: TransactionClass]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const currency = inject<ComputedRef<string>>('currency')

const instance = ref<TransactionClass>()

const isButtonDisabled = computed(() => !type.value || !amount.value)

const type = computed(() => instance.value?.field(TRANSACTION_FIELD.TYPE).value)
const amount = computed(() => instance.value?.entries[1].amount)

const handleSubmit = () => {
  if (!instance.value) return
  emit('submit', instance.value)
}

onMounted(() => {
  instance.value = new TransactionClass(props.defaultData)
})
</script>

<style scoped>
.private-fund-flows-form {
  @apply grid grid-cols-[.9fr_1.3fr_.9fr];
  @apply gap-2 sm:gap-3;

  &__button {
    @apply col-span-3;
  }
}
</style>
