<template>
  <div class="wizzard__form">
    <Flows
      :flows="contributions"
      :restrictions
      @submit="handleSubmit"
      @remove="handleRemove"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { TransactionClass } from '@types'

import {
  CONTRIBUTION_TYPES,
  CONTRIBUTION_TYPES_NO_DRAWDOWN,
} from './utils/const'

import Flows from './components/Flows.vue'

const contributions = defineModel<TransactionClass[]>('contributions', {
  required: true,
})
const isNoDrawdown = defineModel<boolean>('isNoDrawdown', { required: true })

defineOptions({ inheritAttrs: false })

const restrictions = computed(() => {
  return isNoDrawdown.value
    ? CONTRIBUTION_TYPES_NO_DRAWDOWN
    : CONTRIBUTION_TYPES
})

const handleSubmit = (data: TransactionClass) => {
  contributions.value?.push(data)
}

const handleRemove = (index: number) => {
  contributions.value?.splice(index, 1)
}
</script>
