<template>
  <AppReloadPage />
  <component :is="layout">
    <router-view />
  </component>
  <AppAsyncNotification />
  <AppModalFactory :instances="modalsItems" />
  <AppIntercomLauncher v-if="!hideIntercomLauncher" />
  <AppPreloader v-if="!isAppInitialized" />
</template>

<script setup lang="ts">
import {
  computed,
  onBeforeUnmount,
  onMounted,
  provide,
  watch,
  watchEffect,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useLocalStorage, useTitle } from '@vueuse/core'

import { LinkedDataConnectors } from './views/LinkedData/utils/enums'
import { ModalFactoryInstances } from './components/App/ModalFactory/utils/types'

import {
  BLUR_DIGITAL_VALUES,
  ENV_SHORT_NAMES,
  OWNER_MODE,
  READONLY_MODE,
  READWRITE_MODE,
  ROUTE_NAME,
  SET_CURRENT_REPOSITORY,
} from '@/const'

import DefaultLayout from '@/layouts/Default.vue'
import EmptyLayout from '@/layouts/Empty.vue'
import MainLayout from '@/layouts/Main/Main.vue'

import { useLinkedDataStore } from './store/linkedData'
import { useMainStore } from './store/main'
import { useModalsStore } from './store/modals'
import { useRepositoriesStore } from './store/repositories'

import AppAsyncNotification from '@/plugins/notification/NotificationGroup.vue'
import { useCapacitor } from '@/plugins/capacitor'
import { useNotifications } from './plugins/notification'

import { handleCatchedError } from './helpers/common'

import { useAlertsService } from '@/services/alerts'

import {
  AppIntercomLauncher,
  AppModalFactory,
  AppPreloader,
  AppReloadPage,
} from '@app'

defineOptions({
  name: 'App',
  components: {
    DefaultLayout,
    MainLayout,
    EmptyLayout,
  },
})

const linkedDataStore = useLinkedDataStore()
const mainStore = useMainStore()
const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()

const capacitor = useCapacitor()

const { error } = useNotifications()

useAlertsService()

const route = useRoute()
const router = useRouter()

const blurDigitalValues = useLocalStorage(BLUR_DIGITAL_VALUES, false)

const isAppInitialized = computed(() => mainStore.getAppInitialized)
const modalsItems = computed(() => modalsStore.getList as ModalFactoryInstances)
const isOwner = computed(() => repositoriesStore.isOwner)
const isReadonly = computed(() => repositoriesStore.isReadonly)
const isReadWrite = computed(() => repositoriesStore.isReadWrite)
const repositoryId = computed(() => repositoriesStore.currentRepositoryId)
const repositoryName = computed(
  () => repositoriesStore.getCurrentRepository?.name,
)
const currency = computed(
  () => repositoriesStore.getCurrentRepositoryFormattedCurrency,
)
provide('currency', currency)

const title = computed(() => {
  const envString = __IS_PROD__
    ? ''
    : `[${ENV_SHORT_NAMES[__APP_MODE__] || '??'}] `
  const repoString = repositoryName.value ? `${repositoryName.value} | ` : ''
  const titleString = route.meta.title || 'Allposit Inc.'
  return `${envString}${repoString}${titleString}`
})
useTitle(title)

provide(OWNER_MODE, isOwner)
provide(READONLY_MODE, isReadonly)
provide(READWRITE_MODE, isReadWrite)

const setCurrentRepository = async (id?: string) => {
  await repositoriesStore.setCurrentRepositoryId(id)
}
provide(SET_CURRENT_REPOSITORY, setCurrentRepository)

const layout = computed(() => route?.meta?.layout || 'DefaultLayout')
const hideIntercomLauncher = computed(() => route?.meta?.hideIntercomLauncher)

watch(
  blurDigitalValues,
  value => {
    if (value) {
      document.body.classList.add('blur-numbers')
    } else {
      document.body.classList.remove('blur-numbers')
    }
  },
  { immediate: true },
)

watchEffect(() => {
  // global flags for router, menu etc
  // @ts-ignore
  window[OWNER_MODE] = isOwner.value
  // @ts-ignore
  window[READONLY_MODE] = isReadonly.value
  // @ts-ignore
  window[READWRITE_MODE] = isReadWrite.value
})

watchEffect(async () => {
  const CONNECTORS_LIST = Object.values(LinkedDataConnectors).map(item =>
    item.toLowerCase(),
  )
  if (route?.name !== ROUTE_NAME.LINKED_DATA || !repositoryId.value) return
  const errorDescription = route?.query.error_description?.toString()
  if (errorDescription) {
    await error({
      message: errorDescription,
    })
    router.push({ name: ROUTE_NAME.LINKED_DATA })
    return
  }
  const state = route?.query.state?.toString()
  const code = route?.query.code?.toString()
  if (!state || !code) return
  const [providerName, connectorName] = state.split(',')
  if (providerName === undefined || !CONNECTORS_LIST.includes(providerName))
    return
  try {
    await linkedDataStore.callback(code, providerName, connectorName)
  } catch (e) {
    handleCatchedError(e as string)
  }
  router.push({ name: ROUTE_NAME.LINKED_DATA })
})

onBeforeUnmount(() => {
  // @ts-ignore
  delete window[OWNER_MODE]
  // @ts-ignore
  delete window[READONLY_MODE]
  // @ts-ignore
  delete window[READWRITE_MODE]
})

onMounted(() => {
  capacitor?.init()
})
</script>

<style>
.blur-numbers {
  .blurable-number {
    @apply blur-sm hover:blur-none;
    @apply text-inherit;

    &--md {
      @apply blur hover:blur-none;
      @apply text-inherit;
    }

    .grid-cell__amount {
      @apply text-inherit;
    }
  }
}
</style>
