<template>
  <PanelContainer
    ref="containerRef"
    class="main-desktop-panel-asset-tree"
    :class="containerClasses"
    :style="containerStyles"
  >
    <div
      v-if="isCollapsed"
      class="main-desktop-panel-asset-tree__caption"
      @click="resetWidth"
    >
      <span>{{ t('Asset Tree') }}</span>
    </div>
    <MainTree v-else />
    <div
      ref="resizerRef"
      class="main-desktop-panel-asset-tree__resizer"
      :class="resizerClasses"
    />
    <div class="main-desktop-panel-asset-tree__toggler">
      <UIButtonIcon
        v-tooltip.top="buttonTooltip"
        :trailing="buttonIcon"
        variant="gray"
        size="xxs"
        fill="light"
        @click="handleToggle"
      />
    </div>
  </PanelContainer>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount, useTemplateRef, watch } from 'vue'
import {
  useDebounceFn,
  useDraggable,
  useElementBounding,
  useLocalStorage,
  useResizeObserver,
} from '@vueuse/core'

import { useLocale } from '@/plugins/i18n'
import { useHotKeys } from '@/plugins/hotKeys'

import { useUserSettingsStore } from '@/store/user/settings'

import MainTree from './components/Tree.vue'
import PanelContainer from './components/PanelContainer.vue'
import { UIButtonIcon } from '@ui/buttons'
import PanelCloseIcon from '@/assets/images/icons/panel-close.svg'
import TreeIcon from '@/assets/images/icons/tree.svg'

const START_WIDTH = 350
const MIN_WIDTH = 240
const MIN_CONTENT_WIDTH = 370

const { t } = useLocale('layouts.Main')

const userSettingsStore = useUserSettingsStore()

const containerRef = useTemplateRef<HTMLDivElement>('containerRef')
const resizerRef = useTemplateRef('resizerRef')

const containerWidth = ref(MIN_WIDTH)

const isInited = ref(false)

const isTreeOpened = useLocalStorage<boolean>('TREE_OPENED', true)

const { left, width } = useElementBounding(containerRef)
const { isDragging, x } = useDraggable(resizerRef, {
  axis: 'x',
})

const storageWidth = computed({
  get() {
    return userSettingsStore.getAssetTreeWidth || START_WIDTH
  },
  set(value) {
    userSettingsStore.setAssetTreeWidth(value)
  },
})

const isCollapsed = computed(() => containerWidth.value < MIN_WIDTH)
const maxWidth = computed(() => document.body.clientWidth - MIN_CONTENT_WIDTH)

const containerClasses = computed(() => {
  return {
    'main-desktop-panel-asset-tree--collapsed': isCollapsed.value,
  }
})

const containerStyles = computed(() => {
  return isCollapsed.value
    ? undefined
    : {
        width: `${containerWidth.value}px`,
      }
})

const resizerClasses = computed(() => {
  return {
    'main-desktop-panel-asset-tree__resizer--dragging': isDragging.value,
    'main-desktop-panel-asset-tree__resizer--disabled': isCollapsed.value,
  }
})

const buttonTooltip = computed(() => {
  return isCollapsed.value ? t('Open Asset Tree') : t('Close Asset Tree')
})

const buttonIcon = computed(() => {
  return isCollapsed.value ? TreeIcon : PanelCloseIcon
})

const handleToggle = () => {
  isCollapsed.value ? resetWidth() : (containerWidth.value = 0)
}

const initWidth = () => {
  containerWidth.value = isTreeOpened.value ? storageWidth.value : 0
}

const resetWidth = () => {
  containerWidth.value = storageWidth.value
}

const updateStorageWidth = useDebounceFn((value: number) => {
  storageWidth.value = value < MIN_WIDTH ? MIN_WIDTH + 10 : value
}, 500)

const updateWidth = () => {
  let newWidth
  const offset = x.value > 0 ? x.value : left.value + width.value
  if (offset > maxWidth.value) {
    newWidth = maxWidth.value - left.value
  } else {
    newWidth = offset - left.value
  }
  containerWidth.value = newWidth
  updateStorageWidth(newWidth)
}

const updateWidthDebounced = useDebounceFn(updateWidth, 300)

useResizeObserver(document.body, () => {
  if (isCollapsed.value) return
  if (!isInited.value) {
    isInited.value = true
    return
  }
  updateWidthDebounced()
})

watch(isDragging, value => {
  if (value) {
    document.body.classList.add('select-none', 'cursor-col-resize')
  } else {
    document.body.classList.remove('select-none', 'cursor-col-resize')
  }
})

watch(x, () => {
  if (!isDragging.value) return
  updateWidth()
})

watch(isCollapsed, value => {
  isTreeOpened.value = !value
})

onBeforeMount(initWidth)

useHotKeys('tree', handleToggle)
</script>

<style>
.main-desktop-panel-asset-tree {
  .ui-button__icon {
    @apply size-4;
  }
  &--collapsed {
    @apply w-[calc(1.75rem+1px)];
    @apply text-gray-400 dark:text-gray-400;
    @apply hover:text-gray-500 dark:hover:text-gray-200;
    @apply cursor-pointer;
  }

  &__caption {
    @apply absolute inset-0;
    @apply text-xs font-medium;
    @apply tracking-wide leading-none;
    @apply whitespace-nowrap;

    span {
      @apply absolute top-1/2 left-1/2;
      @apply -translate-x-1/2 -translate-y-1/2;
      @apply origin-center;
      @apply rotate-90;
    }
  }

  &__resizer {
    @apply w-[5px];
    @apply absolute top-[3.25rem] bottom-1 -right-[3px];
    @apply bg-primary-selected;
    @apply cursor-col-resize;
    @apply opacity-0;
    @apply z-10;

    &--dragging,
    &:hover {
      @apply opacity-40;
    }

    &--disabled {
      @apply opacity-0;
      @apply pointer-events-none;
    }
  }

  &__toggler {
    @apply absolute top-[.7rem] left-full;
    @apply bg-body-dark;
    @apply border border-l-0 border-common-border;
    @apply rounded-r;
    @apply z-20;

    .main-desktop-panel-asset-tree--collapsed & {
      @apply left-1/2 -translate-x-1/2;
      @apply mt-[1px];
      @apply border-none;
      @apply hover:z-[41];
    }

    .ui-button {
      @apply -ml-1;
      @apply hover:bg-transparent;
      @apply z-[11];

      .main-desktop-panel-asset-tree--collapsed & {
        @apply ml-0;
      }
    }
  }
}
</style>
