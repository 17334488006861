<template>
  <RepositoryWrapper>
    <template #default="{ repositoryName, postfixIcon }">
      <div v-bind="$attrs" @click="handleClick">
        <RectangleStackIcon
          class="main-mobile-footer-icon"
          aria-hidden="true"
        />
        <component
          :is="postfixIcon"
          v-if="postfixIcon"
          class="main-mobile-footer__status-icon"
          aria-hidden="true"
        />
        <div class="main-mobile-footer-caption">{{ repositoryName }}</div>
      </div>
    </template>
  </RepositoryWrapper>
</template>

<script setup lang="ts">
import { markRaw } from 'vue'

import { useModalsStore } from '@/store/modals'

import Repositories from '@/modules/modals/Repositories/List/Repositories.vue'
import RepositoryWrapper from '@/modules/modals/Repositories/RepositoryWrapper.vue'
import { RectangleStackIcon } from '@heroicons/vue/24/outline'

const modalsStore = useModalsStore()

const handleClick = () => {
  const modalInstance = modalsStore.init('repositories', markRaw(Repositories))
  modalInstance?.open(modalsStore.getZIndex(), {})
}
</script>
