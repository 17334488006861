<template>
  <AccountProfile />
  <AccountSecurity />
  <AccountPassword v-if="isPasswordProvided" />
  <AccountSetPassword v-else />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import AccountProfile from './AccountProfile.vue'
import AccountSecurity from './AccountSecurity.vue'
import AccountPassword from './AccountPassword.vue'
import AccountSetPassword from './AccountSetPassword.vue'

import { useUserStore } from '@/store/user'

defineOptions({ name: 'AccountSettings' })

const userStore = useUserStore()

const isPasswordProvided = computed(() => userStore.isPasswordProvided)
</script>
