<template>
  <WizzardContainer
    v-if="asset && transaction"
    :model-value="currentStep"
    v-bind="{ isOpen, zIndex }"
    :steps
    title="Add Real Estate"
    @close="handleClose"
    @update:model-value="handleStepChange"
  >
    <component
      :is="currentComponent"
      ref="currentComponentRef"
      v-bind="{ asset, transaction, tags, prices }"
      v-model:prices="prices"
      @update:tags="tags = $event"
    />
    <template #buttons>
      <UIButton
        v-bind="{ disabled }"
        :label="buttonLabel"
        @click="handleButtonClick"
      />
    </template>
    <template v-if="loading" #loading>
      Please wait. <br />We are creating your Real Estate...
    </template>
  </WizzardContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, provide, ref, useTemplateRef, watch } from 'vue'

import {
  Asset,
  AssetClass,
  AssetPriceForm,
  ModalClass,
  ModalState,
  StepsNavigationStatus,
  TagClass,
  TransactionClass,
} from '@types'

import {
  ASSET_CLASS_LIST,
  INVESTMENT_STRATEGY_LIST,
  STEPS_LIST,
} from './utils/const'
import { ASSET_CLASS, INVESTMENT_STRATEGY } from '../utils/const'
import { ASSET_FIELD, NOTIFICATION_DELAY } from '@/const'

import { getCurrencySymbol } from '@/helpers/common'
import { getCurrentDate } from '@/helpers/dates'

import { useNotifications } from '@/plugins/notification'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsStore } from '@/store/assets'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import { UIButton } from '@ui/buttons'
import RealEstateStep1 from './RealEstateProperty.vue'
import RealEstateStep2 from './RealEstatePurchase.vue'
import RealEstateStep3 from '../modules/StepValues.vue'
import RealEstateStep4 from '../modules/StepTags.vue'
import RealEstateStep5 from './RealEstateSummary.vue'
import WizzardContainer from '../components/WizzardContainer.vue'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

defineOptions({
  components: {
    RealEstateStep1,
    RealEstateStep2,
    RealEstateStep3,
    RealEstateStep4,
    RealEstateStep5,
  },
})

const { error, success } = useNotifications()

const analyticsStore = useAnalyticsStore()
const assetsStore = useAssetsStore()
const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const tagsBunchStore = useTagsBunchStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const currentComponentRef = useTemplateRef<any>('currentComponentRef')

provide('valuePlaceholder', 'Value')
provide('tagAssetClassList', ASSET_CLASS_LIST)
provide('tagInvestmentStrategyList', INVESTMENT_STRATEGY_LIST)

const currentStep = ref(0)
const loading = ref(false)

const asset = ref<AssetClass>()
const transaction = ref<TransactionClass>()
const prices = ref<AssetPriceForm[]>([])
const tags = ref({
  [ASSET_CLASS]: ASSET_CLASS_LIST[0],
  [INVESTMENT_STRATEGY]: INVESTMENT_STRATEGY_LIST[0],
})

const currentComponent = computed(
  () => `RealEstateStep${currentStep.value + 1}`,
)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const defaultDate = computed(() => analyticsStore.getDate || getCurrentDate({}))
provide('defaultDate', defaultDate)

const buttonLabel = computed(() => {
  const length = STEPS_LIST.length - 1
  switch (currentStep.value) {
    case length:
      return 'Create Real Estate'
    default:
      return 'Next'
  }
})

const isValidAsset = computed(() => {
  return asset.value?.isValid
})

const isValidTransaction = computed(() => {
  if (!transaction.value) return false
  const keys = Object.keys(transaction.value.errors)
  const entry1Title = transaction.value.settings?.entries[0]?.title
  const entry2Title = transaction.value.settings?.entries[1]?.title

  return (
    transaction.value.isValid ||
    keys.every(key =>
      [
        `"${entry1Title}" account`,
        `"${entry1Title}" asset`,
        `"${entry2Title}" asset`,
      ].includes(key),
    )
  )
})

const isTagsFilled = computed(() => {
  return Object.values(tags.value).every(tag => tag)
})

const steps = computed(() =>
  STEPS_LIST.map((item, index) => {
    let status
    switch (index) {
      case 0:
        status = isValidAsset.value
          ? StepsNavigationStatus.COMPLETED
          : undefined
        break
      case 1:
        status = isValidTransaction.value
          ? StepsNavigationStatus.COMPLETED
          : undefined
        break
      case 2:
        status = prices.value.length
          ? StepsNavigationStatus.COMPLETED
          : StepsNavigationStatus.SKIPPED
        break
      case 3:
        status = isTagsFilled.value
          ? StepsNavigationStatus.COMPLETED
          : undefined
        break
    }
    return {
      ...item,
      status,
    }
  }),
)

const disabled = computed(() => {
  const status = steps.value[currentStep.value].status
  return (
    (!status ||
      ![
        StepsNavigationStatus.COMPLETED,
        StepsNavigationStatus.SKIPPED,
      ].includes(status)) &&
    currentStep.value !== steps.value.length - 1
  )
})

const repositoryId = computed(() => repositoriesStore.getCurrentRepository?.id)
const currency = computed(() => repositoriesStore.getCurrentRepositoryCurrency)

const assetCurrency = computed(
  () =>
    asset.value?.field<string>(ASSET_FIELD.CURRENCY).value || currency.value,
)

const currencySymbol = computed(() => getCurrencySymbol(assetCurrency.value))
provide('currency', currencySymbol)

const handleClose = () => {
  props.modal.close()
}

const handleCreate = async () => {
  if (!asset.value || !transaction.value) return
  loading.value = true
  try {
    const propertyName = asset.value.field<string>(ASSET_FIELD.NAME).value
    const account = new AssetClass(
      {
        type: 'grouping account',
        name: propertyName,
        ticker: propertyName,
        repository_id: repositoryId.value,
        currency: currency.value,
      },
      true,
    )
    await account.store()
    const tagsIds = []
    for await (const [tag_name, tag_value] of Object.entries(tags.value)) {
      if (!tag_value) return
      const tagInstance = tagsBunchStore.getElementByPair(tag_name, tag_value)
      let tagId
      if (tagInstance?.id) {
        tagId = tagInstance?.id
      } else {
        const tag = new TagClass({ tag_name, tag_value })
        await tag.store()
        tagId = tag.id
      }
      tagsIds.push(tagId)
    }
    if (tagsIds.length) {
      asset.value.field<string[]>(ASSET_FIELD.TAGS).value = tagsIds
    }
    const assetName = `${propertyName} Property`
    asset.value.field<string>(ASSET_FIELD.NAME).value = assetName
    asset.value.field<string>(ASSET_FIELD.TICKER).value = assetName
    await asset.value.store()
    const currencyAsset = asset.value?.field<Asset | undefined>(
      ASSET_FIELD.CURRENCY_ASSET,
    ).value
    let currencyId = currencyAsset?.id
    if (!currencyId) {
      currencyId = assetsBunchStore.getElementByTicker(assetCurrency.value)?.id
    }
    transaction.value.pasteAccountId1(account.id, false)
    transaction.value.pasteAssetId1(asset.value.id, false)
    currencyId && transaction.value.pasteAssetId2(currencyId, false)
    await transaction.value.store()
    // assign prices
    await Promise.all(
      prices.value.map(
        price =>
          asset.value &&
          assetsStore.addAssetPrice(
            {
              ...price,
              asset_id: asset.value.id,
            },
            false,
          ),
      ),
    )
    handleClose()
    await success(
      {
        message: 'Your Real Estate has been successfully created',
      },
      NOTIFICATION_DELAY,
    )
  } catch (e) {
    typeof e !== 'object' && (await error({ message: 'Something went wrong' }))
  } finally {
    loading.value = false
  }
}

const handleStepChange = async (value: number) => {
  if (await currentComponentRef.value?.nextStepDisallowed?.()) return
  currentStep.value = value
}

const handleButtonClick = async () => {
  if (await currentComponentRef.value?.nextStepDisallowed?.()) return
  if (currentStep.value === STEPS_LIST.length - 1) {
    handleCreate()
    return
  }
  currentStep.value++
}

watch(
  () => asset.value?.field(ASSET_FIELD.NAME).value,
  value => {
    asset.value && (asset.value.field(ASSET_FIELD.TICKER).value = value)
  },
)

watch(
  isOpen,
  value => {
    if (!value) {
      asset.value = undefined
      transaction.value = undefined
      prices.value = []
      currentStep.value = 0
      tags.value[ASSET_CLASS] = ASSET_CLASS_LIST[0]
      tags.value[INVESTMENT_STRATEGY] = INVESTMENT_STRATEGY_LIST[0]
      return
    }
    asset.value = new AssetClass({
      type: 'property',
      name: '',
      ticker: '',
      repository_id: repositoryId.value,
      currency: assetCurrency.value,
    })
    transaction.value = new TransactionClass({
      type: 'add position',
      date: defaultDate.value,
      entries: [
        { account_id: '', amount: 1, asset_id: '' },
        {
          account_id: '',
          amount: null,
          asset_id: '',
        },
      ],
    })
    setTimeout(() => {
      asset.value?.validateData()
      transaction.value?.validateData()
    }, 0)
  },
  { immediate: true },
)

onMounted(() => {
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>
