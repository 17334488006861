<template>
  <WizzardContainer
    v-if="asset && transaction"
    :model-value="currentStep"
    v-bind="{ isOpen, zIndex }"
    title="Add Fine Art and Collectables"
    :steps
    @close="handleClose"
    @update:model-value="handleStepChange"
  >
    <component
      :is="currentComponent"
      ref="currentComponentRef"
      v-bind="{
        hasExistingAccounts,
        account,
        asset,
        transaction,
        prices,
        tags,
      }"
      v-model:use-existing-account="useExistingAccount"
      v-model:prices="prices"
      @update:tags="tags = $event"
    />
    <template #buttons>
      <UIButton
        v-bind="{ disabled }"
        :label="buttonLabel"
        tabindex="11"
        @click="handleButtonClick"
      />
    </template>
    <template v-if="loading" #loading>
      Please wait. <br />We are creating your Fine Art and Collectables...
    </template>
  </WizzardContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, provide, ref, useTemplateRef, watch } from 'vue'

import {
  Asset,
  AssetClass,
  AssetPriceForm,
  ModalClass,
  ModalState,
  StepsNavigationStatus,
  TagClass,
  TransactionClass,
} from '@types'

import {
  ASSET_CLASS_LIST,
  ASSET_TYPE_LIST,
  INVESTMENT_STRATEGY_LIST,
  STEPS_LIST,
  TRANSACTION_TYPE,
} from './utils/const'
import { ASSET_CLASS, INVESTMENT_STRATEGY } from '../utils/const'
import { ASSET_FIELD, NOTIFICATION_DELAY } from '@/const'

import { getCurrencySymbol } from '@/helpers/common'
import { getAssetListValues } from '@/entities/transactions/components/utils/helpers'
import { getCurrentDate } from '@/helpers/dates'

import { useNotifications } from '@/plugins/notification'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useAssetsStore } from '@/store/assets'
import { useRepositoriesStore } from '@/store/repositories'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

const currentComponentRef = useTemplateRef<any>('currentComponentRef')

import { UIButton } from '@ui/buttons'
import FineArtAndCollectablesStep1 from '../modules/StepAccount.vue'
import FineArtAndCollectablesStep2 from '../modules/StepInvestment.vue'
import FineArtAndCollectablesStep3 from '../modules/StepValues.vue'
import FineArtAndCollectablesStep4 from '../modules/StepTags.vue'
import FineArtAndCollectablesStep5 from './FineArtAndCollectablesSummary.vue'
import WizzardContainer from '../components/WizzardContainer.vue'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

defineOptions({
  components: {
    FineArtAndCollectablesStep1,
    FineArtAndCollectablesStep2,
    FineArtAndCollectablesStep3,
    FineArtAndCollectablesStep4,
    FineArtAndCollectablesStep5,
  },
})

const { error, success } = useNotifications()

const analyticsStore = useAnalyticsStore()
const assetsStore = useAssetsStore()
const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const tagsBunchStore = useTagsBunchStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

provide('assetNamePlaceholder', 'Camille Corot, Girl Reading')
provide('assetRestrictions', ASSET_TYPE_LIST)
provide('tagAssetClassList', ASSET_CLASS_LIST)
provide('tagInvestmentStrategyList', INVESTMENT_STRATEGY_LIST)

const currentStep = ref(0)
const loading = ref(false)

const useExistingAccount = ref(true)

const account = ref<AssetClass>()
const asset = ref<AssetClass>()
const transaction = ref<TransactionClass>()
const prices = ref<AssetPriceForm[]>([])
const tags = ref({
  [ASSET_CLASS]: ASSET_CLASS_LIST[0],
  [INVESTMENT_STRATEGY]: INVESTMENT_STRATEGY_LIST[0],
})

const currentComponent = computed(
  () => `FineArtAndCollectablesStep${currentStep.value + 1}`,
)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const defaultDate = computed(() => analyticsStore.getDate || getCurrentDate({}))
provide('defaultDate', defaultDate)

const buttonLabel = computed(() => {
  const length = STEPS_LIST.length - 1
  switch (currentStep.value) {
    case length:
      return 'Create Fine Art and Collectables'
    default:
      return 'Next'
  }
})

const accountId = computed(() => transaction.value?.entries[0].account_id)

const isValidAccount = computed(() => {
  return (useExistingAccount.value && accountId.value) || account.value?.isValid
})

const isValidAsset = computed(() => {
  return asset.value?.isValid
})

const isValidTransaction = computed(() => {
  if (!transaction.value) return false
  const keys = Object.keys(transaction.value.errors)
  const entry1Title = transaction.value.settings?.entries[0]?.title
  const entry2Title = transaction.value.settings?.entries[1]?.title
  return (
    transaction.value.isValid ||
    keys.every(key =>
      [
        `"${entry1Title}" account`,
        `"${entry1Title}" asset`,
        `"${entry2Title}" asset`,
      ].includes(key),
    )
  )
})

const isTagsFilled = computed(() => {
  return Object.values(tags.value).every(tag => tag)
})

const steps = computed(() =>
  STEPS_LIST.map((item, index) => {
    let status
    switch (index) {
      case 0:
        status = isValidAccount.value
          ? StepsNavigationStatus.COMPLETED
          : undefined
        break
      case 1:
        status =
          isValidTransaction.value && isValidAsset.value
            ? StepsNavigationStatus.COMPLETED
            : undefined
        break
      case 2:
        status = prices.value.length
          ? StepsNavigationStatus.COMPLETED
          : StepsNavigationStatus.SKIPPED
        break
      case 3:
        status = isTagsFilled.value
          ? StepsNavigationStatus.COMPLETED
          : undefined
        break
    }
    return {
      ...item,
      status,
    }
  }),
)

const disabled = computed(() => {
  const status = steps.value[currentStep.value].status
  return (
    (!status ||
      ![
        StepsNavigationStatus.COMPLETED,
        StepsNavigationStatus.SKIPPED,
      ].includes(status)) &&
    currentStep.value !== steps.value.length - 1
  )
})

const repositoryId = computed(() => repositoriesStore.getCurrentRepository?.id)
const currency = computed(() => repositoriesStore.getCurrentRepositoryCurrency)

const assetCurrency = computed(
  () =>
    asset.value?.field<string>(ASSET_FIELD.CURRENCY).value || currency.value,
)

const currencySymbol = computed(() => getCurrencySymbol(assetCurrency.value))
provide('currency', currencySymbol)

const hasExistingAccounts = computed(() => {
  if (!transaction.value?.settings) return
  const data = Array.from(assetsBunchStore.getList.values()).map(instance =>
    instance.get(),
  )

  return !!getAssetListValues(
    transaction.value.settings.entries[0].account,
    data,
  )?.length
})

const handleClose = () => {
  props.modal.close()
}

const handleCreate = async () => {
  if (!asset.value || !transaction.value) return
  loading.value = true
  try {
    const createAccount = !useExistingAccount.value && account.value
    if (createAccount) {
      await account.value?.store()
      account.value &&
        transaction.value.pasteAccountId1(account.value.id, false)
    }
    const tagsIds = []
    for await (const [tag_name, tag_value] of Object.entries(tags.value)) {
      if (!tag_value) return
      const tagInstance = tagsBunchStore.getElementByPair(tag_name, tag_value)
      let tagId
      if (tagInstance?.id) {
        tagId = tagInstance?.id
      } else {
        const tag = new TagClass({ tag_name, tag_value })
        await tag.store()
        tagId = tag.id
      }
      tagsIds.push(tagId)
    }
    if (tagsIds.length) {
      asset.value.field<string[]>(ASSET_FIELD.TAGS).value = tagsIds
    }
    await asset.value.store()
    const currencyAsset = asset.value?.field<Asset | undefined>(
      ASSET_FIELD.CURRENCY_ASSET,
    ).value
    let currencyId = currencyAsset?.id
    if (!currencyId) {
      currencyId = assetsBunchStore.getElementByTicker(assetCurrency.value)?.id
    }
    transaction.value.pasteAssetId1(asset.value.id, false)
    currencyId && transaction.value.pasteAssetId2(currencyId, false)
    if (prices.value.length) {
      // assign prices
      await Promise.all(
        prices.value.map(
          price =>
            asset.value &&
            assetsStore.addAssetPrice(
              {
                ...price,
                asset_id: asset.value.id,
              },
              false,
            ),
        ),
      )
    }
    // save subscribe and commit
    await transaction.value.store()
    await success(
      {
        message: 'Your Fine Art and Collectables has been successfully created',
      },
      NOTIFICATION_DELAY,
    )
    // open asset summary
    asset.value?.openDrawer()
    handleClose()
  } catch (e) {
    typeof e !== 'object' && (await error({ message: 'Something went wrong' }))
  } finally {
    loading.value = false
  }
}

const handleStepChange = async (value: number) => {
  if (await currentComponentRef.value?.nextStepDisallowed?.()) return
  currentStep.value = value
}

const handleButtonClick = async () => {
  if (await currentComponentRef.value?.nextStepDisallowed?.()) return
  if (currentStep.value === STEPS_LIST.length - 1) {
    handleCreate()
    return
  }
  currentStep.value++
}

watch(
  hasExistingAccounts,
  value => {
    if (value === undefined) return
    useExistingAccount.value = value
  },
  { immediate: true },
)

watch(
  () => account.value?.field(ASSET_FIELD.NAME).value,
  value => {
    account.value && (account.value.field(ASSET_FIELD.TICKER).value = value)
  },
)

watch(
  () => asset.value?.field(ASSET_FIELD.NAME).value,
  value => {
    asset.value && (asset.value.field(ASSET_FIELD.TICKER).value = value)
  },
)

watch(
  isOpen,
  value => {
    if (!value) {
      useExistingAccount.value = true
      account.value = undefined
      asset.value = undefined
      transaction.value = undefined
      prices.value = []
      currentStep.value = 0
      tags.value[ASSET_CLASS] = ASSET_CLASS_LIST[0]
      tags.value[INVESTMENT_STRATEGY] = INVESTMENT_STRATEGY_LIST[0]
      return
    }
    account.value = new AssetClass({
      type: undefined,
      name: '',
      ticker: '',
      repository_id: repositoryId.value,
      currency: assetCurrency.value,
    })
    asset.value = new AssetClass({
      type: ASSET_TYPE_LIST[0],
      name: '',
      ticker: '',
      repository_id: repositoryId.value,
      currency: assetCurrency.value,
    })
    transaction.value = new TransactionClass({
      type: TRANSACTION_TYPE,
      date: defaultDate.value,
      entries: [
        { account_id: '', amount: 1, asset_id: '' },
        {
          account_id: '',
          amount: null,
          asset_id: '',
        },
      ],
    })
    setTimeout(() => {
      account.value?.validateData()
      asset.value?.validateData()
      transaction.value?.validateData()
    }, 0)
  },
  { immediate: true },
)

onMounted(() => {
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>
