<template>
  <RepositoryWrapper>
    <template #default="{ postfixIcon, repositoryName }">
      <div
        v-if="isNavbarOpened"
        class="navbar-node navbar-item__repos"
        @click="handleClick"
      >
        <div class="navbar-node__icon">
          <RectangleStackIcon aria-hidden="true" />
        </div>
        <div class="navbar-node__label">
          {{ t('Repositories') }}
          <component
            :is="postfixIcon"
            class="navbar-item__status"
            aria-hidden="true"
          />
        </div>
      </div>
      <UIButtonIcon
        v-else
        v-tooltip.right="repositoryName"
        data-refid="navbarRepository"
        :leading="RectangleStackIcon"
        variant="gray"
        fill="outlined"
        size="sm"
        class="navbar-item-menu navbar-item__repos"
        @click="handleClick"
      >
        {{ t('Repos') }}
        <template v-if="postfixIcon && !isNavbarOpened">
          <component
            :is="postfixIcon"
            class="navbar-item__status navbar-item__status--float"
            aria-hidden="true"
          />
        </template>
      </UIButtonIcon>
    </template>
  </RepositoryWrapper>
</template>

<script setup lang="ts">
import { inject, markRaw } from 'vue'

import { OpenedFlag } from './utils/type'

import { NAVBAR_OPENED } from './utils/const'

import { useLocale } from '@/plugins/i18n'

import { useModalsStore } from '@/store/modals'

import Repositories from '@/modules/modals/Repositories/List/Repositories.vue'
import RepositoryWrapper from '@/modules/modals/Repositories/RepositoryWrapper.vue'
import { RectangleStackIcon } from '@heroicons/vue/24/outline'
import { UIButtonIcon } from '@ui/buttons'

const { t } = useLocale('layouts.Main')

const modalsStore = useModalsStore()

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)

const handleClick = () => {
  const modalInstance = modalsStore.init('repositories', markRaw(Repositories))
  modalInstance?.open(modalsStore.getZIndex(), {})
}
</script>

<style>
.navbar-item__repos {
  @apply mt-auto;
}
</style>
