import { computed, ref, watch } from 'vue'

import { TaskType } from '@types'

import { handleCatchedError } from '@/helpers/common'
import { useAlerts } from '@/plugins/alerts'

import { useLinkedDataConnector } from '@/hooks/linkedDataConnector'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsStore } from '@/store/assets'
import { useLinkedDataStore } from '@/store/linkedData'
import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useLinkedDataTransactionsStore } from '@/store/linkedData/transactions'
import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'
import { useRepositoriesStore } from '@/store/repositories'
import { useTasksStore } from '@/store/tasks'
import { useTransactionsStore } from '@/store/transactions'
import { useTagsStore } from '@/store/tags'
import { UIPreloader } from '@ui/core'

export const useAlertsService = () => {
  // INIT
  const { progress, remove } = useAlerts()

  const analyticsStore = useAnalyticsStore()
  const assetsStore = useAssetsStore()
  const linkedDataStore = useLinkedDataStore()
  const linkedDataConnectorsStore = useLinkedDataConnectorsStore()
  const linkedDataTransactionsStore = useLinkedDataTransactionsStore()
  const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()
  const repositoriesStore = useRepositoriesStore()
  const tagsStore = useTagsStore()
  const tasksStore = useTasksStore()
  const transactionsStore = useTransactionsStore()

  const { syncing } = useLinkedDataConnector()

  const syncAlertId = ref<string>()
  const importAlertId = ref<string>()

  // GETTERS
  const isRepositoryImporting = computed(
    () =>
      !!tasksStore.getProcessing.find(task =>
        task.type.startsWith(TaskType.REPOSITORY_IMPORT),
      ),
  )

  // WATCHERS
  watch(
    syncing,
    async value => {
      if (value) {
        syncAlertId.value && (await remove(syncAlertId.value))
        const alert = await progress({
          message:
            'Your accounts are now being refreshed. This may take several minutes.',
          icon: UIPreloader,
        })
        syncAlertId.value = alert.id
      } else if (syncAlertId.value) {
        await remove(syncAlertId.value)
        syncAlertId.value = undefined
        try {
          Promise.all([
            assetsStore.fetchAssets(),
            transactionsStore.fetch(),
            tagsStore.fetch(),
            linkedDataStore.fetch(),
            linkedDataTransactionsStore.fetch(),
            linkedDataTransactionsTagsStore.fetch(),
            linkedDataConnectorsStore.fetch(),
          ])
          analyticsStore.markAsDeprecated()
        } catch (e) {
          handleCatchedError(e as string)
        }
      }
    },
    { immediate: true },
  )

  watch(isRepositoryImporting, async values => {
    if (values) {
      importAlertId.value && (await remove(importAlertId.value))
      const alert = await progress({
        message:
          'Your repository is now being imported. This may take several minutes.',
        icon: UIPreloader,
      })
      importAlertId.value = alert.id
    } else if (importAlertId.value) {
      await remove(importAlertId.value)
      importAlertId.value = undefined
      try {
        repositoriesStore.fetchRepositories()
      } catch (e) {
        handleCatchedError(e as string)
      }
    }
  })
}
