<template>
  <User>
    <template #default="{ data }">
      <UIDropdownActions
        :data
        placement="bottom-end"
        data-refid="headerUser"
        popper-class="main-desktop-header-user__popper"
      >
        <template #default="{ toggle }">
          <div
            class="main-desktop-header-user"
            :class="mainClasses"
            @click="toggle"
          >
            <div v-if="userEmail" class="main-desktop-header-user__email">
              {{ userEmail }}
            </div>
            <UserCircleIcon
              v-else
              aria-hidden="true"
              class="main-desktop-header-icon"
            />
            <ChevronDownIcon class="main-desktop-header-user__chevron" />
          </div>
        </template>
        <template #before="{ hide }">
          <AppUserInfo v-bind="{ hide }" />
        </template>
        <template #after>
          <div class="main-desktop-header-user__version">
            {{ appVersion }}
          </div>
        </template>
      </UIDropdownActions>
    </template>
  </User>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useUserStore } from '@/store/user'

import User from './components/User.vue'
import { ChevronDownIcon, UserCircleIcon } from '@heroicons/vue/24/outline'
import { UIDropdownActions } from '@ui/dropdowns'
import { AppUserInfo } from '@app'

const appVersion = `${__APP_VERSION__ || ''}`

const userStore = useUserStore()

const userEmail = computed(() => userStore.getEmail)

const mainClasses = computed(() => ({
  'main-desktop-header-user--shrink': !userEmail.value,
}))
</script>

<style>
.main-desktop-header-user {
  @apply h-8;
  @apply flex items-center;
  @apply shrink-0;
  @apply gap-0.5 pr-1.5 pl-2.5;
  @apply rounded-full;
  @apply bg-primary-dark;
  @apply border border-transparent hover:border-primary-active;
  @apply cursor-pointer;

  &--shrink {
    @apply pl-1;
  }

  &__email {
    @apply text-xs;
    @apply text-indigo-200;
  }

  &__chevron {
    @apply w-3 h-3;
    @apply shrink-0;
    @apply text-indigo-100;
  }

  &__version {
    @apply my-2 px-4;
    @apply text-xs;
    @apply text-light-text;
    @apply text-right;
  }

  &__popper {
    .ui-dropdown-list__container {
      @apply my-0;
    }

    .ui-dropdown-list-item {
      @apply flex-row-reverse;
      @apply text-sm;

      &__icon {
        @apply ml-0 -mr-1;
      }
    }
  }
}
</style>
