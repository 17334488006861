<template>
  <UICard title="Analytics settings">
    <UICheckbox
      v-model="analyticsDebugTabs"
      label="Display Analytics debug tab"
    />
  </UICard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useSettingsStore } from '@/store/settings'

import { UICard } from '@ui/core'
import { UICheckbox } from '@ui/controllers'

const settingsStore = useSettingsStore()

const analyticsDebugTabs = computed<boolean>({
  get() {
    return settingsStore.getAnalyticsDebugTabs
  },
  set(value) {
    settingsStore.setAnalyticsDebugTabs(value)
  },
})
</script>
