<template>
  <UICard
    title="Region settings"
    description="These settings apply to the full app"
  >
    <UIInputSelect
      :model-value="dateFormat"
      label="Date format"
      :data
      @update:model-value="handleSelect"
    />
  </UICard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DATE_FORMATS } from '@/helpers/dates/utils/const'

import { useUserSettingsStore } from '@/store/user/settings'

import { UICard } from '@ui/core'
import { UIInputSelect } from '@ui/fields'

const userSettingsStore = useUserSettingsStore()

const data = computed(() =>
  DATE_FORMATS.map(value => ({ value, label: value.toLowerCase() })),
)

const dateFormat = computed(() => userSettingsStore.getDateFormat)

const handleSelect = (value?: string) => {
  if (!value) return
  userSettingsStore.setDateFormat(value)
}
</script>
