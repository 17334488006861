<template>
  <UICard title="Grid settings" description="These settings apply to all grids">
    <div class="settings-common-grid">
      <UICheckbox
        v-model="extraColumns"
        label="Display CREATED AT and UPDATED AT columns"
      />
      <UICheckbox v-model="blurDigitalValues" label="Blur digital values" />
    </div>
  </UICard>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useLocalStorage } from '@vueuse/core'

import { BLUR_DIGITAL_VALUES } from '@/const/storage'

import { useSettingsStore } from '@/store/settings'

import { UICard } from '@ui/core'
import { UICheckbox } from '@ui/controllers'

const settingsStore = useSettingsStore()

const blurDigitalValues = useLocalStorage(BLUR_DIGITAL_VALUES, false)

const extraColumns = computed<boolean>({
  get() {
    return settingsStore.getGridExtraColumns
  },
  set(value) {
    settingsStore.setGridExtraColumns(value)
  },
})
</script>

<style>
.settings-common-grid {
  @apply flex flex-col;
  @apply gap-2;
}
</style>
