<template>
  <UIDottedMenu :items size="xxs" class="app-tree__menu" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AnalyticsPreparedTree, DottedMenuItem } from '@types'

import {
  CheckIcon,
  CurrencyDollarIcon,
  FunnelIcon,
  PencilIcon,
} from '@heroicons/vue/24/outline'

import { UIDottedMenu } from '@ui/core'

type Props = {
  item: AnalyticsPreparedTree
  isLeaf: boolean
  keyValue: string
  currentNode?: string
  editable: boolean
  selected: boolean
}

type Emits = {
  (e: 'select', keyValue: string, isLeaf: boolean): void
  (e: 'edit', item: AnalyticsPreparedTree, isLeaf: boolean): void
  (e: 'set-price', keyValue: string): void
  (e: 'filter', item: AnalyticsPreparedTree, isLeaf: boolean): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const filtered = computed(() => {
  return props.item.path?.at(-1) === props.currentNode
})

const items = computed(() => {
  const result: DottedMenuItem[] = []

  if (props.editable) {
    result.push({
      tooltip: props.selected ? 'Deselect' : 'Select',
      leading: CheckIcon,
      variant: props.selected ? 'secondary' : 'gray',
      fill: props.selected ? 'filled' : 'outlined',
      action: () => emit('select', props.keyValue, props.isLeaf),
    })

    result.push({
      tooltip: 'Edit',
      leading: PencilIcon,
      variant: 'gray',
      fill: 'outlined',
      action: () => emit('edit', props.item, props.isLeaf),
    })
  }

  if (props.isLeaf) {
    result.push({
      tooltip: 'Set price',
      leading: CurrencyDollarIcon,
      variant: 'gray',
      fill: 'outlined',
      action: () => emit('set-price', props.keyValue),
    })
  } else {
    result.push({
      tooltip: 'Filter analytics',
      leading: FunnelIcon,
      variant: filtered.value ? 'secondary' : 'gray',
      fill: filtered.value ? 'filled' : 'outlined',
      action: () => emit('filter', props.item, props.isLeaf),
    })
  }

  return result
})
</script>

<style>
.app-tree__menu {
  .ui-dotted-menu__area {
    @apply !text-body-dark group-hover:!text-body-gray;

    .ui-tree__item:has(.app-tree__checkbox--checked) & {
      @apply !text-indigo-50 dark:!text-gray-900;
    }

    .ui-slide-panel & {
      @apply !text-body dark:!text-body-dark;
    }

    .ui-slide-panel .ui-tree__item:hover & {
      @apply !text-body-dark dark:!text-body-gray;
    }

    .ui-slide-panel .ui-tree__item:has(.app-tree__checkbox--checked) & {
      @apply !text-indigo-50 dark:!text-gray-900;
    }
  }
}
</style>
