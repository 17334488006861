export const STEPS_LIST = [
  {
    name: 'Investing or Holding Entity',
  },
  {
    name: 'Fine Art and Collectables',
  },
  {
    name: 'Valuation',
    description: 'optional',
  },
  {
    name: 'Asset tags',
  },
  {
    name: 'Summary',
  },
]

export const TRANSACTION_TYPE = 'add position'

export const ASSET_TYPE_LIST = ['fine art and collectables']

export const ASSET_CLASS_LIST = ['Fine Art and Collectables']

export const INVESTMENT_STRATEGY_LIST = ['Fine Art', 'Collectables']
