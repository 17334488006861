<template>
  <div class="add-dialog-item">
    <UIButtonIcon
      :leading="item?.icon"
      :disabled="!!displayPlan"
      :label="displayLabel"
      variant="gray"
      fill="outlined"
      size="2xl"
      class="add-dialog-item__button"
      @click="handleClick"
    />
    <div
      v-if="displayPlan"
      v-tooltip="'Upgrade your plan to access the widget'"
      class="add-dialog-item__plan"
      @click="handleClickUpgrade"
    >
      {{ displayPlan }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AddDialogItem } from './utils/types'

import { UIButtonIcon } from '@ui/buttons'

type Props = {
  item: AddDialogItem
}

type Emits = {
  click: [data: AddDialogItem]
  upgrade: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const displayLabel = computed(() => props.item?.label)
const displayPlan = computed(() => {
  return props.item.unavailable ? 'Upgrade to PRO' : undefined
})

const handleClick = () => {
  emit('click', props.item)
}

const handleClickUpgrade = () => {
  emit('upgrade')
}
</script>

<style>
.add-dialog-item {
  @apply relative;

  &__plan {
    @apply absolute top-6 left-1/2 -translate-x-1/2;
    @apply bg-indigo-500;
    @apply py-0.5 px-2;
    @apply rounded-full;
    @apply text-xs;
    @apply text-white;
    @apply whitespace-nowrap;
    @apply cursor-pointer;
  }
}
</style>
