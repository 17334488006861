<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      v-bind="{ zIndex }"
      size="2md"
      title="Settings"
      @hide="handleClose"
    >
      <UITabs v-model="tab" :tabs bottom-border class="settings-page__nav" />
      <div v-if="tab === SettingTabs.COMMON" class="settings-page__tab">
        <CommonSettings />
      </div>
      <div v-if="tab === SettingTabs.ACCOUNT" class="settings-page__tab">
        <AccountSettings />
      </div>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { SettingTabs } from './utils/enums'
import { ModalClass, ModalState } from '@types'

import { UIDialog } from '@ui/modals'
import { UITabs } from '@ui/core'

import CommonSettings from './Common/Common.vue'
import AccountSettings from './Account/Account.vue'

type Props = {
  modal: ModalClass<any>
  tab?: SettingTabs
}

const props = defineProps<Props>()

const tab = ref<SettingTabs>(SettingTabs.COMMON)

const tabs = computed(() =>
  Object.values(SettingTabs).map(name => ({
    name,
  })),
)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)
const zIndex = computed(() => props.modal.zIndex)

const handleClose = () => {
  props.modal.close()
}

watch(
  isOpen,
  value => {
    if (!value) return
    tab.value = props.tab || SettingTabs.COMMON
  },
  { immediate: true },
)
</script>

<style>
.settings-page {
  &__nav {
    @apply -mt-4;
  }

  &__tab {
    @apply flex flex-col;
    @apply gap-4 py-4;
  }
}
</style>
